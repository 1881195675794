import React from 'react';
import { Row, Col, Button, Upload, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Text } from '../components';
import UploadIcon from '../assets/icons/uploadIcon.svg';
import PdfIcon from '../assets/icons/pdfIcon.svg';
import PhotoIcon from '../assets/icons/photoIcon.svg';

const FileUploader = ({
  fileSizeLimit,
  fileType,
  file,
  setFile,
  fieldName,
}) => {
  const handleFileUpload = ({ file }) => {
    if (file.size > fileSizeLimit) {
      message.error(
        `${file.name} is larger than ${
          fileSizeLimit / 1024 / 1024
        } MB. Please select a smaller file.`,
      );

      return;
    }

    // Add timestamp to the file name
    const timestamp = dayjs().format('YYYYMMDDHHmmss');
    const newFileName = `${file.name.split('.')[0]}_${timestamp}.${file.name
      .split('.')
      .pop()}`;
    const newFile = new File([file], newFileName, { type: file.type });

    setFile({ fieldName, file: newFile });
  };

  const handleFileDelete = () => {
    setFile(null);
  };

  return (
    <Row gutter={[16, 16]}>
      {!file ? (
        <Col xs={24} md={12}>
          <Upload
            showUploadList={false}
            accept={fileType}
            customRequest={handleFileUpload}
          >
            <Button
              type="link"
              icon={<img src={UploadIcon} width={16} height={16} />}
              style={{
                display: 'flex',
                float: 'left',
                padding: '0',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <Text sub3 className="text-normal">
                Upload File
              </Text>
            </Button>
          </Upload>
        </Col>
      ) : (
        <Col xs={24} md={12}>
          <Row className="card-container-bg-grey">
            <Col
              xs={2}
              md={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              {file.file.type === 'application/pdf' ? (
                <img src={PdfIcon} height={18} width={18} />
              ) : (
                <img src={PhotoIcon} height={18} width={18} />
              )}
            </Col>

            <Col
              xs={20}
              md={22}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Text body14 className="text-secondary-blue">
                {file.file.name}
              </Text>
            </Col>

            <Col
              xs={2}
              md={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <DeleteOutlined
                onClick={handleFileDelete}
                className="text-secondary-red"
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default FileUploader;
