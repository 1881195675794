import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { Table } from '../Table';
import { Button, Col, Row } from 'antd';
import { Text } from '../Text';
import moment from 'moment';
import CertificateModal from './modal/certificateModal';
import { AppContext } from '../context/AppContext';
import { ROLE_ID } from '../../utils/constants.js';

const CertificateInformation = (props) => {
  const { certificate, getCertificates, userId } = props;

  const { userState } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [record, setRecord] = useState();

  const defaultStringCompare = (property) => (a, b) =>
    a[property].localeCompare(b[property]);

  const openCertificateModal = async (record) => {
    setRecord(record);
    setModalVisible(true);
  };

  const closeCertificateModal = () => {
    setRecord(null);
    setModalVisible(false);
  };
  const columns = [
    {
      title: 'Certificate Name',
      index: 'certificateName',
      dataIndex: 'certificateName',
      width: '250px',
      sorter: {
        compare: (a, b) => a.certificateName.localeCompare(b.certificateName),
      },
      render: (certificateName) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {certificateName}
          </Text>
        </div>
      ),
    },
    {
      title: 'Certificate Date',
      dataIndex: 'certificateDate',
      width: '140px',
      sorter: defaultStringCompare('certificateDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      width: '80px',
      render: (key, record) => (
        <a onClick={() => openCertificateModal(record)}>
          {userState.currentRoleId === 2 ? (
            <EditOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]} className="card-container">
          <Col span={24}>
            <Row gutter={[16, 16]} justify="space-between">
              <Text sub2 className="text-normal">
                Certificates
              </Text>
              {userState.currentRoleId === ROLE_ID.User && (
                <Button
                  type="primary"
                  className="button-primary"
                  width="149px"
                  icon={<PlusOutlined />}
                  onClick={() => openCertificateModal()}
                >
                  Create Certificate
                </Button>
              )}
            </Row>
          </Col>
          <Col span={24} style={{ padding: 0 }}>
            <Table
              columns={columns}
              dataSource={certificate.certificates}
              rowKey={(record) =>
                `${record.certificateName}-${record.certificateDate}`
              }
              loading={isLoading}
              pagination={{
                position: ['bottomRight'],
                pageSizeOptions: [10, 20, 30, 40],
                showSizeChanger: true,
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
      </Col>
      <CertificateModal
        getCertificates={getCertificates}
        userId={userId}
        modalVisible={modalVisible}
        closeModal={closeCertificateModal}
        record={record}
      />
    </Row>
  );
};
export default CertificateInformation;
