import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Modal, message, Select, Form, Divider } from 'antd';
import { ProjectService, TimesheetService } from '../../services/api';
import { useLocation } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { Text, DatePicker, FormInput, Checkbox } from '../../components';
import { useForm } from 'antd/lib/form/Form';
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';

const CreateOrEditTaskModal = (props) => {
  const {
    openCreateTaskModal,
    openEditTaskModal,
    handleOpenCreateTaskModal,
    handleOpenEditTaskModal,
    selectedTask,
    selectedDate,
    taskList,
  } = props;

  const [form] = useForm();
  const [activeProject, setActiveProject] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [timeIn, setTimeIn] = useState('09:00');
  const [timeOut, setTimeOut] = useState('18:00');
  const [isAllday, setIsAllDay] = useState(false);
  const [isError, setError] = useState(false);

  const location = useLocation();

  const id = new URLSearchParams(location.search).get('id');

  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);
  const userId = id ? id : decode1.userId;

  useEffect(() => {
    handleFetchActiveProjectOfUser();
    handleFetchTimeInTimeOutValue();
    if (openCreateTaskModal) {
      form.setFieldsValue({
        projectId: undefined,
        taskTypeId: undefined,
        startDate: selectedDate ? moment(selectedDate, 'DD/MM/YYYY') : moment(),
        endDate: selectedDate ? moment(selectedDate, 'DD/MM/YYYY') : moment(),
        startTime: moment({ hour: timeIn }).format('HH:mm:ss'),
        endTime: moment({ hour: timeOut }).format('HH:mm:ss'),
        description: undefined,
      });
    }
    if (openEditTaskModal) {
      form.setFieldsValue({
        projectId: selectedTask.projectId,
        taskTypeId: selectedTask.taskTypeId,
        startDate: moment(selectedTask.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: moment(selectedTask.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
        startTime: moment(selectedTask.startTime).format('HH:mm:ss'),
        endTime: moment(selectedTask.endTime).format('HH:mm:ss'),
        description: selectedTask.taskDescription,
      });
    }
  }, [
    form,
    selectedDate,
    selectedTask,
    openCreateTaskModal,
    openEditTaskModal,
  ]);

  const handleFetchTimeInTimeOutValue = async () => {
    TimesheetService.getConfigs(
      ({ data }) => {
        const { TIME_IN, TIME_OUT } = data;
        setTimeIn(TIME_IN);
        setTimeOut(TIME_OUT);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(
            'Failed to fetch time in - time out value: ' +
              response.data.message,
          );
        }
      },
    );
  };

  const handleFetchActiveProjectOfUser = async () => {
    const params = {
      memberId: userId,
    };
    await ProjectService.fetchActiveProjectListByUserNewApi(
      params,
      async ({ data }) => {
        const activeData = [];
        data.map((record) => {
          if (record.isActive) {
            activeData.push(record);
          }
        });
        setActiveProject(activeData);
      },
      (response) => {
        if (response && response.status === 400) {
          if (response.data.message !== 'empty array') {
            message.error(
              'Failed to fetch active project of user: ' +
                response.data.message,
            );
          }
        }
      },
    );
  };

  const handleSubmit = async (values) => {
    setIsButtonLoading(true);
    if (openEditTaskModal) {
      await handleEditTask(values);
    } else {
      await handleCreateTask(values);
    }
    setIsButtonLoading(false);
  };

  const handleCreateTask = async (values) => {
    const payload = {
      userId: userId,
      projectId: values.projectId,
      taskTypeId: values.taskTypeId,
      taskDescription: values.description,
      taskDate: dayjs(values.startDate.toDate()).format('YYYY-MM-DD'),
      startTime: dayjs(
        dayjs(values.startDate.toDate()).format('YYYY-MM-DD') +
          ' ' +
          (!isAllday ? values.startTime : timeIn),
      ).toDate(),
      endTime: dayjs(
        dayjs(values.endDate.toDate()).format('YYYY-MM-DD') +
          ' ' +
          (!isAllday ? values.endTime : timeOut),
      ).toDate(),
      endTaskDate: dayjs(values.endDate.toDate()).format('YYYY-MM-DD'),
    };
    await TimesheetService.addTimesheetNewApi(
      payload,
      ({ data }) => {
        if (data.existDate.length <= 0) {
          message.success('Task has been created successfully.');
        } else {
          let result = data.existDate.map((num) => num).join(', ');
          if (data.existDate.length === 1) {
            message.warning(
              `Tasks have been partially created. ${result} is already used.`,
            );
          } else {
            message.warning(
              `Tasks have been partially created. ${result} are already used.`,
            );
          }
        }
        handleOpenCreateTaskModal();
        form.resetFields();
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`Failed to create task: ${response.data.errors}`);
        }
      },
    );
  };

  const handleEditTask = async (values) => {
    const payload = {
      userId,
      timesheetId: selectedTask.id,
      projectId: values.projectId,
      taskTypeId: values.taskTypeId,
      taskDescription: values.description,
      taskDate: dayjs(values.startDate.toDate()).format('YYYY-MM-DD'),
      startTime: dayjs(
        dayjs(values.startDate.toDate()).format('YYYY-MM-DD') +
          ' ' +
          (!isAllday ? values.startTime : timeIn),
      ).toDate(),
      endTime: dayjs(
        dayjs(values.endDate.toDate()).format('YYYY-MM-DD') +
          ' ' +
          (!isAllday ? values.endTime : timeOut),
      ).toDate(),
      endTaskDate: dayjs(values.endDate.toDate()).format('YYYY-MM-DD'),
    };
    await TimesheetService.updateTimesheetNewApi(
      payload,
      ({ data }) => {
        message.success('Task has been updated successfully.');
        handleOpenEditTaskModal();
        form.resetFields();
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`Failed to update task: ${response.data.errors}`);
        }
      },
    );
  };

  const handleFormOnFinishFailed = (errorInfo) => {
    errorInfo.errorFields.forEach((error) => {
      if (error.name[0] === 'description') {
        setError(true);
      }
    });
  };

  function getHourFromDate(dateStr) {
    return parseInt(dateStr.split(':')[0]);
  }

  function getTimeOption() {
    let res = [];
    let start = moment(timeIn, 'HH:mm');
    let end = getHourFromDate(timeOut);
    while (start.hour() !== end || start.minute() === 0) {
      res.push(
        <Select.Option
          key={start.format('HH:mm:ss')}
          value={start.format('HH:mm:ss')}
        >
          {start.format('HH:mm')}
        </Select.Option>,
      );
      start.add(30, 'minutes');
    }
    return res;
  }

  return (
    <Modal
      width={480}
      open={openCreateTaskModal || openEditTaskModal}
      centered
      onCancel={() => {
        form.resetFields();
        setError(false);
        if (openCreateTaskModal) {
          handleOpenCreateTaskModal();
        }
        if (openEditTaskModal) {
          handleOpenEditTaskModal();
        }
      }}
      footer={
        <Col span={24}>
          <Row gutter={[8, 8]} justify="space-between">
            <Col>
              <Button
                className="button-outlined"
                onClick={() => {
                  form.resetFields();
                  setError(false);
                  if (openCreateTaskModal) {
                    handleOpenCreateTaskModal();
                  }
                  if (openEditTaskModal) {
                    handleOpenEditTaskModal();
                  }
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className="button-primary"
                loading={isButtonLoading}
                onClick={() => form.submit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      }
    >
      <Form
        onFinish={handleSubmit}
        onFinishFailed={handleFormOnFinishFailed}
        form={form}
        requiredMark={true}
        initialValues={{
          projectId: openEditTaskModal ? selectedTask.projectId : undefined,
          taskTypeId: openEditTaskModal ? selectedTask.taskTypeId : undefined,
          startDate: openEditTaskModal
            ? moment(selectedTask.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : selectedDate
            ? moment(selectedDate, 'DD/MM/YYYY')
            : moment(),
          endDate: openEditTaskModal
            ? moment(selectedTask.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : selectedDate
            ? moment(selectedDate, 'DD/MM/YYYY')
            : moment(),
          startTime: openEditTaskModal
            ? moment(selectedTask.startTime).format('HH:mm')
            : moment({ hour: timeIn }).format('HH:mm'),
          endTime: openEditTaskModal
            ? moment(selectedTask.endTime).format('HH:mm')
            : moment({ hour: timeOut }).format('HH:mm'),
          description: openEditTaskModal
            ? selectedTask.taskDescription
            : undefined,
        }}
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Row align="middle" gutter={[16, 16]}>
              <Col>
                <Text h4 className="text-normal">
                  {openEditTaskModal ? 'Edit Task' : 'New Task'}
                </Text>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Col span={24}>
            <FormInput
              title="Project"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: '0', maxWidth: '512px' }}
                  name="projectId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select project',
                    },
                  ]}
                >
                  <Select placeholder="Select Project">
                    {activeProject?.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        <Row gutter={[16, 16]} align="middle">
                          <Col span={1}>
                            <div
                              style={{
                                width: '7px',
                                height: '7px',
                                backgroundColor: item.projectColorCode
                                  ? item.projectColorCode
                                  : '#000000',
                                borderRadius: '50%',
                                marginRight: '10px',
                              }}
                            />
                          </Col>
                          <Col span={23}>
                            <Text small12 className="text-normal">
                              {item.projectNo} - {item.projectName}
                            </Text>
                          </Col>
                        </Row>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Task Type"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: '0', maxWidth: '512px' }}
                  name="taskTypeId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select task type',
                    },
                  ]}
                >
                  <Select placeholder="Select task type">
                    {taskList
                      ?.filter((item) => {
                        return item.isActived;
                      })
                      .map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.taskTypeName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Date"
              required
              component={
                <Row direction="row" alignItems="center" gutter={[8, 8]}>
                  <Col>
                    <Form.Item
                      style={{ width: '160px', margin: '0', height: 'auto' }}
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Start Date.',
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear={false}
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        onChange={(value) => {
                          form.setFieldsValue({
                            endDate: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Text small12 className="text-normal">
                      -
                    </Text>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{ width: '160px', margin: '0', height: 'auto' }}
                      name="endDate"
                      dependencies={['startDate', 'endDate']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select End Date',
                        },
                        () => ({
                          validator: (_, value) => {
                            if (
                              moment(form.getFieldValue('startDate')) >
                              moment(value)
                            ) {
                              return Promise.reject(
                                'End Date must be greater than Start Date.',
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        disabled={openEditTaskModal}
                        allowClear={false}
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Time"
              required
              component={
                <Row direction="row" alignItems="center" gutter={[8, 8]}>
                  <Col>
                    {isAllday && (
                      <Select
                        disabled
                        name="isAllDay"
                        placeholder="09:00"
                        style={{ width: '160px', margin: 0 }}
                      >
                        <Select.Option>
                          {moment({ hour: 9 }).format('HH:mm')}
                        </Select.Option>
                      </Select>
                    )}
                    {!isAllday && (
                      <Form.Item
                        style={{
                          width: '160px',
                          margin: '0',
                          height: 'auto',
                        }}
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Start Time.',
                          },
                        ]}
                      >
                        {!isAllday && (
                          <Select style={{ width: '160px', margin: 0 }}>
                            {getTimeOption()}
                          </Select>
                        )}
                      </Form.Item>
                    )}
                  </Col>
                  <Col>
                    <Text small12 className="text-normal">
                      -
                    </Text>
                  </Col>
                  <Col>
                    {isAllday && (
                      <Select
                        disabled
                        placeholder="18:00"
                        style={{ width: '160px', margin: 0 }}
                      >
                        <Select.Option>
                          {moment({ hour: 18 }).format('HH:mm')}
                        </Select.Option>
                      </Select>
                    )}
                    {!isAllday && (
                      <Form.Item
                        style={{
                          width: '160px',
                          margin: '0',
                          height: 'auto',
                        }}
                        name="endTime"
                        dependencies={['startTime', 'endTime']}
                        rules={[
                          {
                            required: true,
                            message: 'Please select End Time',
                          },
                          () => ({
                            validator: (_, value) => {
                              const startTimeMoment = moment(
                                form.getFieldValue('startTime'),
                                'HH:mm',
                              );
                              const endTimeMoment = moment(value, 'HH:mm');
                              if (endTimeMoment.isBefore(startTimeMoment)) {
                                return Promise.reject(
                                  'End Time must be greater than Start Time.',
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        {!isAllday && (
                          <Select style={{ width: '160px', margin: 0 }}>
                            {getTimeOption()}
                          </Select>
                        )}
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <Checkbox
              onChange={() => setIsAllDay(!isAllday)}
              checked={isAllday}
            >
              <Text small12>All day</Text>
            </Checkbox>
          </Col>
          <Col span={24}>
            <FormInput
              title="Description"
              required
              component={
                <Form.Item
                  style={{ width: '100%', maxWidth: '512px', height: '5rem' }}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter description.',
                    },
                  ]}
                >
                  <TextArea
                    status={isError ? 'error' : ''}
                    placeholder="Enter description"
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    onChange={(e) => {
                      e.target.value.length > 0
                        ? setError(false)
                        : setError(true);
                    }}
                  />
                </Form.Item>
              }
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateOrEditTaskModal;
