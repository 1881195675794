import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Divider,
  message,
  Button,
  Modal,
  Col,
  Avatar,
  Layout,
  Form,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { MedicineBoxOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { AppContext } from '../context/AppContext';
import { FeatureFlags } from '../context/FeatureFlagContext';
import { Text, Tag } from '../../components';
import DocumentDisplay from '../../components/DocumentDisplay';
import { Input } from '../../components';
import defaultProfileImage from '../../assets/icons/profileimage.svg';
import systemProfileImage from '../../assets/images/system_profile.jpg';

import { getMedicalClaimPic } from '../../services/image';
import { MedicalClaimService } from '../../services/api';
import { getFileNameFromPath } from '../../services/file';

// api
import {
  UserInfoService,
  DepartmentService,
  PositionService,
} from '../../services/api';

const { Content } = Layout;

const EditMedicalClaimModal = (props) => {
  const [form] = useForm();
  const { features } = useContext(FeatureFlags);
  const { userState } = useContext(AppContext);

  const { open, onCancel, onFetch, record } = props;

  const [isUser, setIsUser] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [department, setDepartment] = useState(null);
  const [position, setPosition] = useState(null);
  const [medicalInvoicePictureFile, setMedicalInvoicePictureFile] = useState();
  const [medicalCertificatePictureFile, setMedicalCertificatePictureFile] =
    useState();

  useEffect(() => {
    if (userState.currentRoleId === 2) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [userState]);

  useEffect(() => {
    if (record) {
      fetchUserInfoUnique();
    }
  }, [record]);

  useEffect(() => {
    userInfo && fetchDepartment();
    userInfo && fetchPosition();
  }, [userInfo]);

  useEffect(() => {
    if (record) {
      const medicalInvoicePictureFileName = getFileNameFromPath(
        record.medicalInvoicePicturePath,
      );
      const medicalCertificatePictureFileName = getFileNameFromPath(
        record.medicalCertificatePicturePath,
      );
      setMedicalInvoicePictureFile({
        fileName: medicalInvoicePictureFileName,
        filePath: record.medicalInvoicePicturePath,
      });
      setMedicalCertificatePictureFile({
        fileName: medicalCertificatePictureFileName,
        filePath: record.medicalCertificatePicturePath,
      });
    }
  }, [record]);

  const fetchUserInfoUnique = () => {
    UserInfoService.fetchUserInfoUnique(
      record.userId,
      ({ data }) => {
        setUserInfo(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching user info: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchDepartment = () => {
    DepartmentService.fetchDepartmentById(
      userInfo?.departmentId,
      ({ data }) => {
        setDepartment(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching department: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchPosition = () => {
    PositionService.fetchPositionById(
      userInfo?.positionId,
      ({ data }) => {
        setPosition(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching position: ${response.data.message}`,
          );
        }
      },
    );
  };

  const handleDownloadMedicalPic = async (path) => {
    const blob = await getMedicalClaimPic(path);

    return blob;
  };

  const onCancelMedicalClaimEdit = () => {
    form.resetFields();
    onCancel();
  };

  const onFinish = (requestStatusId) => {
    let remark = form.getFieldValue('remark');

    if (requestStatusId === 300 && !remark) {
      form.setFields([
        {
          name: 'remark',
          errors: ['Please input reject reason.'],
        },
      ]);

      return;
    }

    if (isUser) {
      const payload = {
        requestStatusId: 400,
      };
      MedicalClaimService.updateMedicalClaimByUser(
        record.medicalClaimRequestId,
        payload,
        ({ data }) => {
          message.success('Medical claim has been updated successfully.');
          setTimeout(() => {
            onFetch();
          }, 1000);
          onCancelMedicalClaimEdit();
        },
        (response) => {
          if (response) {
            message.error(
              `Failed to update medical claim: ${response.data.message}`,
            );
          }
        },
      );
    } else {
      const payload = {
        requestStatusId: requestStatusId,
        remark: remark,
      };
      MedicalClaimService.updateMedicalClaim(
        record.medicalClaimRequestId,
        payload,
        ({ data }) => {
          message.success('Medical claim has been updated successfully.');
          setTimeout(() => {
            onFetch();
          }, 1000);
          onCancelMedicalClaimEdit();
        },
        (response) => {
          if (response)
            message.error(
              `Failed to update medical claim: ${response.data.message}`,
            );
        },
      );

      form.setFields([{ name: 'remark', errors: [] }]);
    }
  };

  return (
    <Modal
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      centered
      width={'40vw'}
      bodyStyle={{
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
    >
      <Layout className="bg-white">
        <Form form={form}>
          <Content
            style={{
              padding: '24px',
              overflow: 'initial',
              gap: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* User Info*/}
            <Row gutter={[16, 16]} align="middle">
              {/* Profile Image*/}
              <Col span={24}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  <Avatar
                    style={{ margin: '0 3%' }}
                    size={32}
                    src={
                      userInfo && userInfo.picturePath
                        ? userInfo.picturePath
                        : defaultProfileImage
                    }
                  />

                  {/* User Name*/}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Text h3 className="text-normal">
                      {userInfo?.firstnameEn + ' ' + userInfo?.lastnameEn}
                    </Text>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Department, Position*/}
            <Row gutter={[16, 16]} align="middle">
              {/* Department */}
              <Col xs={24} sm={12} md={5}>
                <Text
                  sub3
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  Department
                </Text>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Text
                  body14
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  {department?.name}
                </Text>
              </Col>

              {/* Position */}
              <Col xs={24} sm={12} md={4}>
                <Text
                  sub3
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  Position
                </Text>
              </Col>

              <Col xs={24} sm={12} md={7}>
                <Text
                  body14
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  {position?.positionName}
                </Text>
              </Col>
            </Row>

            <Divider style={{ margin: '0' }} />

            <Col span={24}>
              <Row justify="space-between" align="middle" gutter={[8, 8]}>
                <Col>
                  <MedicineBoxOutlined
                    className="text-secondary-red"
                    style={{ marginRight: 5 }}
                  />

                  <Text sub3 className="text-normal">
                    {record?.medicalClaimName}
                  </Text>
                </Col>
                <Col>
                  <Text sub3 className="text-secondary-red">
                    {record?.runningNumber}
                  </Text>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text body14 className="text-normal">
                    Treatment Date
                  </Text>
                </Col>

                <Col>
                  <Text sub3 className="text-normal">
                    {dayjs(record?.effectiveDate).format('DD/MM/YYYY')}
                  </Text>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text body14 className="text-normal">
                    Invoice / Receipt Number
                  </Text>
                </Col>

                <Col>
                  <Text sub3 className="text-normal">
                    {record?.referenceNumber}
                  </Text>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text body14 className="text-normal">
                    Amount
                  </Text>
                </Col>

                <Col>
                  <Text sub3 className="text-normal">
                    {record?.amountUse?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    Baht
                  </Text>
                </Col>
              </Row>
            </Col>

            <Divider style={{ margin: '0' }} />

            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Text body14 className="text-normal">
                    Reason
                  </Text>
                </Col>

                <Col span={24}>
                  <Text sub3 className="text-normal">
                    {record?.reason ? record?.reason : '-'}
                  </Text>
                </Col>
              </Row>
            </Col>

            <Divider style={{ margin: '0' }} />

            <Col span={24}>
              <DocumentDisplay
                titles={['Documents']}
                subtitles={['Medical Invoice', 'Medical Certificate']}
                files={[
                  medicalInvoicePictureFile
                    ? [medicalInvoicePictureFile, medicalCertificatePictureFile]
                    : [],
                ]}
                ZipName={`${record?.runningNumber}_${dayjs().format(
                  'DDMMYYYY_hh.mm',
                )}`}
                primaryLayout={false}
                onLegacyDownload={handleDownloadMedicalPic}
              />
            </Col>

            <Divider style={{ margin: '0' }} />

            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text body14 className="text-normal">
                    Status
                  </Text>
                </Col>

                <Col>
                  {record?.requestStatusDisplay === 'Pending' ? (
                    <Tag yellow>
                      <Text small12>Pending</Text>
                    </Tag>
                  ) : record?.requestStatusDisplay === 'Approved' ? (
                    <Tag green>
                      <Text small12>Approved</Text>
                    </Tag>
                  ) : record?.requestStatusDisplay === 'Rejected' ? (
                    <Tag red>
                      <Text small12>Rejected</Text>
                    </Tag>
                  ) : record?.requestStatusDisplay ===
                    'Canceled by Approver' ? (
                    <Tag grey>
                      <Text small12>Canceled by Approver</Text>
                    </Tag>
                  ) : (
                    <Tag grey>
                      <Text small12>Canceled</Text>
                    </Tag>
                  )}
                </Col>
              </Row>
            </Col>

            <Divider style={{ margin: '0' }} />

            {/* Footer */}
            {record?.requestStatusDisplay === 'Pending' ? (
              !isUser && features?.medicalClaimApproveRejectRequest ? (
                <>
                  <Col span={24} gutter={[16, 16]}>
                    <Row justify="space-between" align="middle">
                      <Col>
                        <Text body14 className="text-normal">
                          Status Date
                        </Text>
                      </Col>

                      <Col>
                        <Text sub3 className="text-normal">
                          {dayjs(record?.updateDatetime).format(
                            'DD MMMM YYYY, HH:mm',
                          )}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Row gutter={[8, 8]}>
                    <Divider style={{ margin: '0' }} />

                    <Col span={24}>
                      <Text body14 className="text-normal">
                        Approve/Reject Reason
                      </Text>
                    </Col>

                    <Col span={24}>
                      <Form.Item name="remark">
                        <Input
                          placeholder="Enter approve/reject reason"
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '8px',
                      }}
                    >
                      <Button
                        type="default"
                        icon={<CloseCircleOutlined />}
                        width="97px"
                        className="button-red"
                        onClick={() => onFinish(300)}
                      >
                        Reject
                      </Button>

                      <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        width="110px"
                        className="button-primary"
                        onClick={() => onFinish(200)}
                      >
                        Approve
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                isUser && (
                  <Col span={24}>
                    <Row justify="space-between" align="middle">
                      <Col>
                        <Text body14 className="text-normal">
                          Status Date
                        </Text>
                      </Col>

                      <Col>
                        <Text sub3 className="text-normal">
                          {dayjs(record?.updateDatetime).format(
                            'DD MMMM YYYY, HH:mm',
                          )}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                )
              )
            ) : record?.requestStatusDisplay !== 'Canceled' ? (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Text body14 className="text-normal">
                        {record
                          ? record.requestStatusDisplay === 'Approved'
                            ? 'Approved by'
                            : record.requestStatusDisplay ===
                              'Canceled by Approver'
                            ? 'Canceled by'
                            : record.requestStatusDisplay === 'Rejected'
                            ? 'Rejected by'
                            : 'Updated by'
                          : ''}
                      </Text>
                    </Col>

                    <Col>
                      <Row gutter={[16, 16]} align="middle">
                        <Col>
                          <Avatar
                            size={32}
                            src={
                              record?.updatedByUserInfo?.picturePath
                                ? record?.updatedByUserInfo?.picturePath
                                : !record?.updatedByUserInfo?.firstnameEn &&
                                  !record?.updatedByUserInfo?.lastnameEn
                                ? systemProfileImage
                                : defaultProfileImage
                            }
                            style={{ margin: '0 3%' }}
                          />
                        </Col>

                        <Col>
                          <Text sub3 className="text-normal">
                            {record?.updatedByUserInfo?.firstnameEn ||
                            record?.updatedByUserInfo?.lastnameEn
                              ? record?.updatedByUserInfo?.firstnameEn +
                                ' ' +
                                record?.updatedByUserInfo?.lastnameEn
                              : 'System Auto-Approval'}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Text body14 className="text-normal">
                        Status Date
                      </Text>
                    </Col>

                    <Col>
                      <Text sub3 className="text-normal">
                        {dayjs(record?.updateDatetime).format(
                          'DD MMMM YYYY, HH:mm',
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[16, 0]} align="middle">
                    <Col span={24}>
                      <Text body14 className="text-normal">
                        Approve/Reject Reason
                      </Text>
                    </Col>

                    <Col span={24}>
                      <Text sub3 className="text-normal">
                        {record?.remark && record?.remark != ''
                          ? record?.remark
                          : '-'}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Col span={24}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text body14 className="text-normal">
                      Status Date
                    </Text>
                  </Col>

                  <Col>
                    <Text sub3 className="text-normal">
                      {dayjs(record?.updateDatetime).format(
                        'DD MMMM YYYY, HH:mm',
                      )}
                    </Text>
                  </Col>
                </Row>
              </Col>
            )}
          </Content>
        </Form>
      </Layout>
    </Modal>
  );
};
export default EditMedicalClaimModal;
