import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Col, Modal, message, Select, Form, Divider } from 'antd';
import { ProjectService, TimesheetService } from '../../services/api';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Text, DatePicker, FormInput, Checkbox } from '../../components';
import { useForm } from 'antd/lib/form/Form';
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';

const CreateOrEditOtModal = (props) => {
  const [form] = useForm();
  const {
    openCreateOtModal,
    openEditOtModal,
    handleOpenCreateOtModal,
    handleOpenEditOtModal,
    selectedTask,
    selectedDate,
    checkHoliday,
    taskList,
  } = props;

  const [projectOt, setProjectOt] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [taskDate, setTaskDate] = useState();
  const [isError, setError] = useState(false);

  const timeIn = '19:00';
  const timeOut = '20:00';

  const location = useLocation();

  const id = new URLSearchParams(location.search).get('id');

  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);
  const userId = id ? id : decode1.userId;

  useEffect(() => {
    setTaskDate(selectedDate);
    handleFetchProjectOtListByUser();
    if (openCreateOtModal) {
      form.setFieldsValue({
        projectId: undefined,
        taskTypeId: undefined,
        taskDate: selectedDate ? moment(selectedDate, 'DD/MM/YYYY') : moment(),
        startTime: moment({ hour: timeIn }).format('HH:mm:ss'),
        endTime: moment({ hour: timeOut }).format('HH:mm:ss'),
        description: undefined,
      });
    }
    if (openEditOtModal) {
      form.setFieldsValue({
        projectId: selectedTask.projectId,
        taskTypeId: selectedTask.taskTypeId,
        taskDate: moment(selectedTask.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
        startTime: moment(selectedTask.startTime).format('HH:mm:ss'),
        endTime: moment(selectedTask.endTime).format('HH:mm:ss'),
        description: selectedTask.taskDescription,
      });
    }
  }, [form, selectedDate, selectedTask, openCreateOtModal, openEditOtModal]);

  const handleFetchProjectOtListByUser = (date) => {
    const taskDate = date
      ? moment(date).format('YYYY-MM-DD')
      : openEditOtModal
      ? moment(selectedTask?.taskDate).format('YYYY-MM-DD')
      : moment(selectedDate).format('YYYY-MM-DD');
    const params = {
      taskDate,
      memberId: userId,
    };
    ProjectService.fetchProjectOtListByUserNewApi(
      params,
      ({ data }) => {
        setProjectOt(data);
      },
      (response) => {
        if (response) {
          message.error(
            'Failed to fetch project ot list by user: ' + response.data.message,
          );
        }
      },
    );
  };

  const getTimeOption = () => {
    let res = [];
    let start = moment('00:00', 'HH:mm');

    if (!checkHoliday(taskDate ?? selectedDate)) {
      for (let i = 0; i < 19; i++) {
        res.push(
          <Select.Option
            key={start.format('HH:mm:ss')}
            value={start.format('HH:mm:ss')}
          >
            {start.format('HH:mm')}
          </Select.Option>,
        );
        start.add(30, 'minutes');
      }
      start = moment('19:00', 'HH:mm:ss');
      for (let i = 0; i < 11; i++) {
        res.push(
          <Select.Option
            key={start.format('HH:mm:ss')}
            value={start.format('HH:mm:ss')}
          >
            {start.format('HH:mm')}
          </Select.Option>,
        );
        start.add(30, 'minutes');
      }
    } else {
      for (let i = 0; i < 49; i++) {
        res.push(
          <Select.Option value={start.format('HH:mm:ss')}>
            {start.format('HH:mm')}
          </Select.Option>,
        );
        start.add(30, 'minutes');
      }
    }

    return res;
  };

  const handleCreateOt = async (values) => {
    const payload = {
      userId: userId,
      projectId: values.projectId,
      taskTypeId: values.taskTypeId,
      taskDescription: values.description,
      taskDate: dayjs(values.taskDate.toDate()).format('YYYY-MM-DD'),
      startTime: dayjs(
        dayjs(values.taskDate.toDate()).format('YYYY-MM-DD') +
          ' ' +
          values.startTime,
      ).toDate(),
      endTime:
        values.endTime == '00:00:00' && values.endTime <= values.startTime
          ? dayjs(values.taskDate.toDate())
              .startOf('day')
              .add(1, 'day')
              .toDate()
          : dayjs(
              dayjs(values.taskDate.toDate()).format('YYYY-MM-DD') +
                ' ' +
                values.endTime,
            ).toDate(),
      endTaskDate: dayjs(values.taskDate.toDate()).format('YYYY-MM-DD'),
    };
    await TimesheetService.addOtNewApi(
      payload,
      ({ data }) => {
        message.success('OT has been created successfully.');
        handleOpenCreateOtModal();
        form.resetFields();
      },
      (response) => {
        if (response) {
          message.error(`Failed to created ot: ${response.data.errors}`);
        }
      },
    );
  };

  const handleEditOt = async (values) => {
    const payload = {
      timesheetId: selectedTask.id,
      projectId: values.projectId,
      taskTypeId: values.taskTypeId,
      taskDescription: values.description,
      taskDate: dayjs(values.taskDate.toDate()).format('YYYY-MM-DD'),
      startTime: dayjs(
        dayjs(values.taskDate.toDate()).format('YYYY-MM-DD') +
          ' ' +
          values.startTime,
      ).toDate(),
      endTime:
        values.endTime == '00:00:00' && values.endTime <= values.startTime
          ? dayjs(values.taskDate.toDate())
              .startOf('day')
              .add(1, 'day')
              .toDate()
          : dayjs(
              dayjs(values.taskDate.toDate()).format('YYYY-MM-DD') +
                ' ' +
                values.endTime,
            ).toDate(),
      endTaskDate: dayjs(values.taskDate.toDate()).format('YYYY-MM-DD'),
    };
    await TimesheetService.updateOverTimeNewApi(
      payload,
      ({ data }) => {
        message.success('OT has been updated successfully.');
        handleOpenEditOtModal();
        form.resetFields();
      },
      (response) => {
        if (response) {
          message.error(`Failed to update ot: ${response.data.errors}`);
        }
      },
    );
  };

  const handleSubmit = async (values) => {
    setIsButtonLoading(true);
    if (openEditOtModal) {
      await handleEditOt(values);
    } else {
      await handleCreateOt(values);
    }
    setIsButtonLoading(false);
  };

  const handleFormOnFinishFailed = (errorInfo) => {
    errorInfo.errorFields.forEach((error) => {
      if (error.name[0] === 'description') {
        setError(true);
      }
    });
  };

  return (
    <Modal
      width={480}
      open={openCreateOtModal || openEditOtModal}
      centered
      onCancel={() => {
        form.resetFields();
        setError(false);
        if (openCreateOtModal) {
          handleOpenCreateOtModal();
        }
        if (openEditOtModal) {
          handleOpenEditOtModal();
        }
      }}
      footer={
        <Col span={24}>
          <Row gutter={[8, 8]} justify="space-between">
            <Col>
              <Button
                className="button-outlined"
                onClick={() => {
                  form.resetFields();
                  setError(false);
                  if (openCreateOtModal) {
                    handleOpenCreateOtModal();
                  }
                  if (openEditOtModal) {
                    handleOpenEditOtModal();
                  }
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className="button-primary"
                loading={isButtonLoading}
                onClick={() => form.submit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      }
    >
      <Form
        onFinish={handleSubmit}
        onFinishFailed={handleFormOnFinishFailed}
        form={form}
        requiredMark={true}
        initialValues={{
          projectId: openEditOtModal ? selectedTask.projectId : undefined,
          taskTypeId: openEditOtModal ? selectedTask.taskTypeId : undefined,
          taskDate: openEditOtModal
            ? moment(selectedTask.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : selectedDate
            ? moment(selectedDate, 'DD/MM/YYYY')
            : moment(),
          startTime: openEditOtModal
            ? moment(selectedTask.startTime).format('HH:mm:ss')
            : moment({ hour: timeIn }).format('HH:mm:ss'),
          endTime: openEditOtModal
            ? moment(selectedTask.endTime).format('HH:mm:ss')
            : moment({ hour: timeOut }).format('HH:mm:ss'),
          description: openEditOtModal
            ? selectedTask.taskDescription
            : undefined,
        }}
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Row align="middle" gutter={[16, 16]}>
              <Col>
                <Text h4 className="text-normal">
                  {openEditOtModal ? 'Edit OT' : 'New OT'}
                </Text>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Col span={24}>
            <FormInput
              title="Date"
              required
              component={
                <Row direction="row" alignItems="center" gutter={[8, 8]}>
                  <Col>
                    <Form.Item
                      style={{ margin: '0', height: 'auto' }}
                      name="taskDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please select date.',
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear={false}
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        onChange={(value) => {
                          setTaskDate(value);
                          form.setFieldsValue({
                            startTime: moment({ hour: timeIn }).format(
                              'HH:mm:ss',
                            ),
                            endTime: moment({ hour: timeOut }).format(
                              'HH:mm:ss',
                            ),
                          });
                          form.validateFields(['startTime', 'endTime']);
                          handleFetchProjectOtListByUser(
                            value.format('YYYY-MM-DD'),
                            userId,
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Project"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: '0', maxWidth: '512px' }}
                  name="projectId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select project',
                    },
                  ]}
                >
                  <Select placeholder="Select Project">
                    {projectOt?.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        <Row gutter={[16, 16]} align="middle">
                          <Col span={1}>
                            <div
                              style={{
                                width: '7px',
                                height: '7px',
                                backgroundColor: item.projectColorCode
                                  ? item.projectColorCode
                                  : '#000000',
                                borderRadius: '50%',
                                marginRight: '10px',
                              }}
                            />
                          </Col>
                          <Col span={23}>
                            <Text small12 className="text-normal">
                              {item.projectNo} - {item.projectName}
                            </Text>
                          </Col>
                        </Row>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Task Type"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: '0', maxWidth: '512px' }}
                  name="taskTypeId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select task type',
                    },
                  ]}
                >
                  <Select placeholder="Select task type">
                    {taskList
                      ?.filter((item) => {
                        return item.isActived;
                      })
                      .map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.taskTypeName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Time"
              required
              component={
                <Row direction="row" alignItems="center" gutter={[8, 8]}>
                  <Col>
                    <Form.Item
                      style={{
                        width: '160px',
                        margin: '0',
                        height: 'auto',
                      }}
                      name="startTime"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Start Time.',
                        },
                      ]}
                    >
                      <Select style={{ width: '160px', margin: 0 }}>
                        {getTimeOption()}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Text small12 className="text-normal">
                      -
                    </Text>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{
                        width: '160px',
                        margin: '0',
                        height: 'auto',
                      }}
                      name="endTime"
                      dependencies={['startTime', 'endTime']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select End Time',
                        },
                        () => ({
                          validator: (_, value) => {
                            const startTimeMoment = moment(
                              form.getFieldValue('startTime'),
                              'HH:mm',
                            );
                            const endTimeMoment = moment(value, 'HH:mm');
                            if (
                              endTimeMoment.isBefore(startTimeMoment) &&
                              value != '00:00:00'
                            ) {
                              return Promise.reject(
                                'End Time must be greater than Start Time.',
                              );
                            }
                            if (
                              startTimeMoment.hour() <= 9 &&
                              endTimeMoment.hour() >= 18 &&
                              !checkHoliday(taskDate ?? selectedDate)
                            ) {
                              return Promise.reject(
                                "Can't select normal working time as OT",
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select style={{ width: '160px', margin: 0 }}>
                        {getTimeOption()}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Description"
              required
              component={
                <Form.Item
                  style={{ width: '100%', maxWidth: '512px', height: '5rem' }}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter description.',
                    },
                  ]}
                >
                  <TextArea
                    status={isError ? 'error' : ''}
                    placeholder="Enter description"
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    onChange={(e) => {
                      e.target.value.length > 0
                        ? setError(false)
                        : setError(true);
                    }}
                  />
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <Text small12 className="text-secondary-red">
              Time at 12:00 - 13:00 and 18:00 - 19:00 will not be counted as OT.
            </Text>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateOrEditOtModal;
