import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { Table } from '../Table';
import { Button, Col, Row } from 'antd';
import { Text } from '../Text';
import moment from 'moment';
import EducationModal from './modal/educationModal';
import { AppContext } from '../context/AppContext';

const MAX_DATE = new Date(8640000000000000);

const EducationInformation = (props) => {
  const { education, getEducation, userId } = props;

  const { userState } = useContext(AppContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [record, setRecord] = useState();

  const defaultStringCompare = (property) => (a, b) =>
    a[property].localeCompare(b[property]);

  const openEducationModal = (record) => {
    setRecord(record);
    setModalVisible(true);
  };

  const closeEducationModal = () => {
    setRecord(null);
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Degree',
      index: 'degree',
      dataIndex: 'degree',
      width: '200px',
      sorter: {
        compare: (a, b) => a.degree.localeCompare(b.degree),
      },
      render: (degree) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {degree}
          </Text>
        </div>
      ),
    },
    {
      title: 'Major',
      index: 'major',
      dataIndex: 'major',
      width: '250px',
      sorter: {
        compare: (a, b) => a.major.localeCompare(b.major),
      },
      render: (major) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 capitalize className="text-normal">
            {major}
          </Text>
        </div>
      ),
    },
    {
      title: 'Institution',
      index: 'institution',
      dataIndex: 'institution',
      width: '250px',
      sorter: {
        compare: (a, b) => a.institution.localeCompare(b.institution),
      },
      render: (institution) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 capitalize className="text-normal">
            {institution}
          </Text>
        </div>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: '140px',
      sorter: defaultStringCompare('startDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Graduation Date',
      dataIndex: 'graduateDate',
      width: '140px',
      sorter: (a, b, sortOrder) => {
        // Note: Assume null as maximum date
        const dateA = a.graduateDate ? moment(a.graduateDate) : MAX_DATE;
        const dateB = b.graduateDate ? moment(b.graduateDate) : MAX_DATE;

        return dateA.valueOf() - dateB.valueOf();
      },

      render: (value) => (
        <Text small12 className="text-normal">
          {value ? moment(value).format('DD/MM/YYYY') : '-'}
        </Text>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      width: '80px',
      render: (key, record) => (
        <a onClick={() => openEducationModal(record)}>
          {userState.currentRoleId === 2 ? (
            <EditOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]} className="card-container">
          <Col span={24}>
            <Row gutter={[16, 16]} justify="space-between">
              <Text sub2 className="text-normal">
                Educations
              </Text>
              {userState?.currentRoleId === 2 && (
                <Button
                  type="primary"
                  className="button-primary"
                  width="149px"
                  icon={<PlusOutlined />}
                  onClick={openEducationModal}
                >
                  Create Education
                </Button>
              )}
            </Row>
          </Col>
          <Col span={24} style={{ padding: 0 }}>
            <Table
              columns={columns}
              dataSource={education.educations}
              rowKey={(record) =>
                `${record.degree}-${record.major}-${record.institution}`
              }
              pagination={{
                position: ['bottomRight'],
                pageSizeOptions: [10, 20, 30, 40],
                showSizeChanger: true,
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
      </Col>
      <EducationModal
        getEducation={getEducation}
        userId={userId}
        modalVisible={modalVisible}
        closeModal={closeEducationModal}
        record={record}
      />
    </Row>
  );
};
export default EducationInformation;
