import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';

const Tabs = styled(AntdTabs)`
  height: 48px;
  background-color: #ffffff;
  gap: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;

  .ant-tabs-nav-wrap {
    padding: 0 16px;
  }
`;

export default Tabs;
