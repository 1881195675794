import React from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { message, Upload, Row, Col, Avatar } from 'antd';
import { Text } from './Text';
import PdfIcon from '../assets/icons/pdfIcon.svg';
import PhotoIcon from '../assets/icons/photoIcon.svg';

const { Dragger } = Upload;

const DocumentUploader = ({
  fileSizeLimit,
  maxFileLimit,
  files,
  setFiles,
  fieldName,
}) => {
  const handleFileUpload = ({ file }) => {
    if (file.size > fileSizeLimit) {
      message.error(
        `${file.name} is larger than ${
          fileSizeLimit / 1024 / 1024
        } MB. Please select a smaller file.`,
      );

      return;
    }

    if (Object.keys(files).length >= maxFileLimit) {
      message.error(`You can only upload ${maxFileLimit} files per request.`);

      return;
    }

    // Append a timestamp to the file name
    const timestamp = dayjs().format('YYYYMMDDHHmmss');
    const fileExtension = file.name.split('.').pop();
    const fileNameWithoutExtension = file.name.substring(
      0,
      file.name.lastIndexOf('.'),
    );
    const newFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;
    const newFile = new File([file], newFileName, { type: file.type });

    setFiles((prev) => {
      return [
        ...prev,
        {
          id: uuidv4(),
          uid: file.uid,
          fieldName,
          file: newFile,
        },
      ];
    });
  };

  const handleFileDelete = (id) => {
    setFiles((prev) => {
      return prev.filter((item) => item.id !== id);
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={12}>
        <Dragger
          multiple
          maxCount={maxFileLimit}
          showUploadList={false}
          customRequest={handleFileUpload}
          accept="application/pdf, image/png, image/jpg, image/jpeg"
        >
          <Avatar size={60} icon={<UploadOutlined size={24} />} />

          <p>
            <Text sub3>Drop files here or click to upload</Text>
          </p>

          <p>
            <Text small12>
              Support file types : PDF, PNG, JPG and JPEG. File limit :{' '}
              {maxFileLimit} files.
            </Text>
          </p>

          <p>
            <Text small12>
              Size limit : {fileSizeLimit / 1024 / 1024} MB per file.
            </Text>
          </p>
        </Dragger>
      </Col>

      <Col xs={24} md={12}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {files.map((fileForm) => {
            return (
              <Row key={fileForm.uid} className="card-container-bg-grey">
                <Col
                  xs={2}
                  md={1}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  {fileForm.file.type === 'application/pdf' ? (
                    <img src={PdfIcon} height={18} width={18} />
                  ) : (
                    <img src={PhotoIcon} height={18} width={18} />
                  )}
                </Col>

                <Col
                  xs={20}
                  md={22}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Text body14 className="text-secondary-blue">
                    {fileForm.file.name}
                  </Text>
                </Col>

                <Col
                  xs={2}
                  md={1}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <DeleteOutlined
                    className="text-secondary-red"
                    onClick={() => handleFileDelete(fileForm.id)}
                  />
                </Col>
              </Row>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default DocumentUploader;
