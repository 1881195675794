import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import { Col, Row, Button, message } from 'antd';
import {
  PlusOutlined,
  EyeFilled,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { AppContext } from '../../components/context/AppContext';
import { Text, Tag, Select, DatePicker, Table } from '../../components';
import BenefitClaimDetailDialog from '../employee/BenefitClaimDetailDialog';
import ConfirmModal from '../../components/ConfirmModal';
import {
  BENEFIT_CLAIM_REQUEST_STATUS_TYPE,
  ROLE_ID,
} from '../../utils/constants';

// api
import { ClaimService } from '../../services/api';
import { UserInfoService } from '../../services/api';

function BenefitClaimHr() {
  const accessToken = sessionStorage.getItem('access-token');
  const userId = decode(accessToken).userId;

  const { userState } = useContext(AppContext);
  const [hrInfoList, setHrInfoList] = useState([]);
  const [userInfoList, setUserInfoList] = useState([]);
  const [claimRequestList, setClaimRequestList] = useState([]);
  const [filteredClaimRequestList, setFilteredClaimRequestList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [filter, setFilter] = useState({
    createdBy: 'People Name: All',
    type: 'All',
    year: dayjs().year(),
  });
  const [isBenefitClaimDetailDialogOpen, setIsBenefitClaimDetailDialogOpen] =
    useState(false);
  const [isCancelClaimRequestModalOpen, setIsCancelClaimRequestModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchHrInfos();
    fetchUserInfos();
    fetchClaimRequests();
  }, [userState]);

  useEffect(() => {
    fetchClaimRequests();
  }, [userInfoList]);

  useEffect(() => {
    if (claimRequestList.length === 0) {
      return;
    }

    filterClaimRequests(filter);
  }, [claimRequestList]);

  const fetchHrInfos = () => {
    UserInfoService.fetchUserInfoList(
      { roleId: 11 },
      ({ data }) => {
        setHrInfoList(data);
      },
      (response) => {
        if (response) {
          message.error(`Error: ${response.data.message}`);
        }
      },
    );
  };

  const fetchUserInfos = () => {
    UserInfoService.fetchUserInfoList(
      {},
      ({ data }) => {
        setUserInfoList(data);
      },
      (response) => {
        if (response) {
          message.error(`Error: ${response.data.message}`);
        }
      },
    );
  };

  const fetchClaimRequests = () => {
    setIsLoading(true);

    if (
      userState.currentRoleId === ROLE_ID.Hr ||
      userState.currentRoleId === ROLE_ID.HrManager ||
      userState.currentRoleId === ROLE_ID.Admin
    ) {
      ClaimService.fetchClaimRequestList(
        {},
        ({ data }) => {
          setClaimRequestList(
            data.map((record) => {
              const userInfo = userInfoList.find(
                (info) => info.userId === record.userId,
              );
              return { ...record, empCode: userInfo?.empCode };
            }),
          );
          setIsLoading(false);
        },
        (response) => {
          setIsLoading(false);

          if (response) {
            message.error(`Error: ${response.data.message}`);
          }
        },
      );
    }
  };

  const filterClaimRequests = (filter) => {
    const { createdBy, type, year } = filter;

    const filteredList = claimRequestList.filter((record) => {
      const matchedCreatedBy =
        createdBy === 'People Name: All' || record.createdBy === createdBy;
      const matchedType =
        type === BENEFIT_CLAIM_REQUEST_STATUS_TYPE.All ||
        record.status === type;
      const matchedYear = year === null || record.year === year;

      return matchedCreatedBy && matchedType && matchedYear;
    });

    setFilteredClaimRequestList(filteredList);
  };

  const handleCreatedByFilterChange = (value) => {
    const updatedFilter = { ...filter, createdBy: value };
    setFilter(updatedFilter);
    filterClaimRequests(updatedFilter);
  };

  const handleStatusTypeFilterChange = (value) => {
    const updatedFilter = { ...filter, type: value };
    setFilter(updatedFilter);
    filterClaimRequests(updatedFilter);
  };

  const handleYearFilterChange = (value) => {
    const year = value ? dayjs(value).year() : null;
    const updatedFilter = { ...filter, year };
    setFilter(updatedFilter);
    filterClaimRequests(updatedFilter);
  };

  const handleOpenBenefitClaimDetailDialog = (record) => {
    setSelectedRecord(record);
    setIsBenefitClaimDetailDialogOpen(true);
  };

  const handleCloseBenefitClaimDetailDialog = () => {
    setIsBenefitClaimDetailDialogOpen(false);
    setSelectedRecord(null);
  };

  const handleOpenCancelClaimRequestModal = (record) => {
    setSelectedRecord(record);
    setIsCancelClaimRequestModalOpen(true);
  };

  const handleCloseCancelClaimRequestModal = () => {
    setIsCancelClaimRequestModalOpen(false);
    setSelectedRecord(null);
  };

  const handleCancelClaimRequest = () => {
    setIsLoading(true);

    ClaimService.createClaimRequestStatus(
      {
        claimRequestId: selectedRecord.id,
        requestStatusTypeId: 400,
        requestStatusUpdatedBy: userId,
      },
      () => {
        setIsLoading(false);
        message.success('Request canceled successfully');
        fetchClaimRequests();
      },
      (response) => {
        setIsLoading(false);

        if (response) {
          message.error(`Error: ${response.data.message}`);
        }
      },
    );

    setIsCancelClaimRequestModalOpen(false);
  };

  const createdByOptions = [
    { value: 'People Name: All', label: 'People Name: All' },
    ...hrInfoList.map((hr) => ({
      value: hr.userId,
      label: `People Name: ${hr.firstnameEn} ${hr.lastnameEn}`,
    })),
  ];

  const statusOptions = Object.entries(BENEFIT_CLAIM_REQUEST_STATUS_TYPE).map(
    ([key, value]) => ({
      value: key,
      label: value,
    }),
  );

  const columns = [
    {
      title: 'Claim No.',
      dataIndex: 'transactionNumber',
      width: '120px',
      fixed: 'left',
      sorter: (a, b) => {
        const numA = parseInt(a.transactionNumber.split('-')[1]);
        const numB = parseInt(b.transactionNumber.split('-')[1]);
        return numA - numB;
      },
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Create Date',
      dataIndex: 'createdAt',
      width: '120px',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (value) => (
        <Text small12 className="text-normal">
          {dayjs(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Emp No.',
      dataIndex: 'empCode',
      width: '80px',
      sorter: (a, b) => a.employeeNumber - b.employeeNumber,
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'claimTypeName',
      width: '284px',
      sorter: (a, b) => a.claimTypeName.localeCompare(b.claimTypeName),
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Event Date',
      dataIndex: 'eventDate',
      width: '142px',
      sorter: (a, b) => new Date(a.eventDate) - new Date(b.eventDate),
      render: (value) => (
        <Text small12 className="text-normal">
          {dayjs(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '150px',
      align: 'right',
      sorter: (a, b) => a.amount - b.amount,
      render: (value) => (
        <Text small12 className="text-normal">
          {value
            ? value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '-'}
        </Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '180px',
      sorter: (a, b) => a['status'].localeCompare(b['status']),
      render: (value) => (
        <Text small12 className="text-normal">
          {value === 'Pending' ? (
            <Tag yellow>{value}</Tag>
          ) : value === 'Approved' ? (
            <Tag green>{value}</Tag>
          ) : value === 'Rejected' ? (
            <Tag red>{value}</Tag>
          ) : value === 'Canceled' ? (
            <Tag grey>{value}</Tag>
          ) : null}
        </Text>
      ),
    },
    {
      title: 'Action',
      width: '120px',
      align: 'center',
      fixed: 'right',
      render: (key, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
          <a onClick={() => handleOpenBenefitClaimDetailDialog(record)}>
            <EyeFilled className="text-primary-blue" />
          </a>

          {userState.currentRoleId === ROLE_ID.HrManager ||
          userState.currentRoleId === ROLE_ID.Admin ? null : record.status ===
              'Pending' && record.createdBy === userId ? (
            <a onClick={() => handleOpenCancelClaimRequestModal(record)}>
              <CloseCircleOutlined className="text-error" />
            </a>
          ) : (
            <div style={{ width: '16px', height: '16px' }}></div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {/* action bar */}
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col flex>
            <Text h3 className="text-normal">
              Benefit Claim
            </Text>
          </Col>

          {/* filter bar */}
          <Col flex="auto">
            <Row gutter={[8, 8]} justify="end">
              <Col flex>
                <Select
                  defaultValue={'People Name: All'}
                  style={{ width: '240px' }}
                  onChange={handleCreatedByFilterChange}
                >
                  {createdByOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col flex>
                <DatePicker
                  style={{ width: '160px' }}
                  defaultValue={dayjs()}
                  picker="year"
                  format="YYYY"
                  disabledDate={(current) => current > dayjs().endOf('year')}
                  onChange={(value) => {
                    handleYearFilterChange(value);
                  }}
                />
              </Col>

              <Col flex>
                <Select
                  defaultValue={'All'}
                  style={{ width: '160px' }}
                  onChange={handleStatusTypeFilterChange}
                >
                  {statusOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              {/* create button */}
              {userState.currentRoleId === ROLE_ID.Hr && (
                <Col>
                  <NavLink to={`/claim/benefit/request`}>
                    <Button
                      type="primary"
                      width="96px"
                      className="button-primary"
                      icon={<PlusOutlined />}
                    >
                      Create Request
                    </Button>
                  </NavLink>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>

      {/* table */}
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={filteredClaimRequestList.map((item) => ({
            ...item,
            key: item.id,
          }))}
          loading={isLoading}
          size="small"
          scroll={{
            x: 300,
            y: 'calc(100vh - 360px)',
          }}
          pagination={{
            position: ['bottomRight'],
            pageSizeOptions: [10, 20, 30, 40],
            showSizeChanger: true,
            defaultPageSize: 20,
          }}
        />
      </Col>

      {isBenefitClaimDetailDialogOpen && (
        <BenefitClaimDetailDialog
          isModalOpen={isBenefitClaimDetailDialogOpen}
          onClose={handleCloseBenefitClaimDetailDialog}
          record={selectedRecord}
          fetchClaimRequests={fetchClaimRequests}
        />
      )}

      {isCancelClaimRequestModalOpen && (
        <ConfirmModal
          title={'Confirm Cancel Request'}
          isModalOpen={isCancelClaimRequestModalOpen}
          onClose={handleCloseCancelClaimRequestModal}
          handleConfirm={handleCancelClaimRequest}
          children={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text body14 className="text-normal">
                Are you sure you want to cancel this claim request?
              </Text>
              <Text body14 className="text-normal">
                Please check your information before proceeding.
              </Text>
            </div>
          }
        />
      )}
    </Row>
  );
}

export default BenefitClaimHr;
