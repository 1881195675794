import { ImageService } from '../services/api';
import profileimage from '../assets/icons/profileimage.svg';

export const getUserPic = async (record) => {
  try {
    // User Profile Image
    await ImageService.image.getImage(record.userInfo.picturePath, (res) => {
      if (!res.status) {
        const url = URL.createObjectURL(res.data);
        record.userImage = url;
      } else {
        record.userImage = profileimage;
      }
    });

    // Admin Profile Image
    await ImageService.image.getImage(
      record.updateUserInfo.picturePath,
      (res) => {
        if (!res.status) {
          const url = URL.createObjectURL(res.data);
          record.updateUserImage = url;
        } else {
          record.updateUserImage = profileimage;
        }
        return record;
      },
    );
  } catch (err) {
    console.log(err);
    return record;
  }
};

export const getMedicalClaimPic = async (path) => {
  try {
    let blob;
    await ImageService.image.getImage(
      path,
      (res) => {
        if (!res.status) {
          blob = res.data;
        }
      },
      (response) => {
        console.log(response);
      },
    );

    return blob;
  } catch (err) {
    console.log(err);

    return;
  }
};
