import { Button, Col, Divider, Form, Modal, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from '../../Text';
import { DatePicker, FormInput, Input, Select } from '../..';
import { AppContext } from '../../context/AppContext';
import moment from 'moment';
import { ProfileService } from '../../../services/api';

const TrainingCourseModal = (props) => {
  const { record, modalVisible, closeModal, userId, getTrainingCourses } =
    props;
  const [isLoading, setIsLoading] = useState(false);

  const { userState } = useContext(AppContext);

  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      courseName: record?.courseName,
      expense: record?.expense,
      institution: record?.institution,
      trainingDescription: record?.trainingDescription,
      startDate: record?.id
        ? moment(record?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : record?.startDate,
      endDate: record?.id
        ? moment(record?.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : record?.endDate,
    });
  }, [record]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    if (!record.id) {
      const payload = {
        userId: userId,
        updateBy: userId,
        courseName: values?.courseName,
        expense: values?.expense,
        institution: values?.institution,
        trainingDescription: values?.trainingDescription,
        startDate: record?.id
          ? moment(values?.startDate).format('YYYY-MM-DD')
          : values?.startDate,
        endDate: record?.id
          ? moment(values?.endDate).format('YYYY-MM-DD')
          : values?.endDate,
      };
      await ProfileService.trainingCourses.addTrainingCourse(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getTrainingCourses();
          message.success('Training course has been created successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(
              `Failed to add Training course: ${response.data.message}`,
            );
          }
        },
      );
    } else {
      const payload = {
        userId: userId,
        updateBy: userId,
        trainingCourseId: record?.id,
        courseName: values?.courseName,
        expense: values?.expense,
        institution: values?.institution,
        trainingDescription: values?.trainingDescription,
        startDate: record?.id
          ? moment(values?.startDate).format('YYYY-MM-DD')
          : values?.startDate,
        endDate: record?.id
          ? moment(values?.endDate).format('YYYY-MM-DD')
          : values?.endDate,
      };
      await ProfileService.trainingCourses.editTrainingCourse(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getTrainingCourses();
          message.success('Training course has been edited successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(
              `Failed to edit training course: ${response.data.message}`,
            );
          }
        },
      );
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await ProfileService.trainingCourses.deleteTrainingCourse(
      record.id,
      ({ data }) => {
        setIsLoading(false);
        getTrainingCourses();
        message.success('Training course has been deleted successfully');
        closeModal();
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error(
            `Failed to delete training course: ${response.data.message}`,
          );
        }
      },
    );
  };

  return (
    <Modal
      width="500px"
      bodyStyle={{
        maxHeight: '640px',
        overflowY: 'auto',
      }}
      open={modalVisible}
      centered
      onCancel={closeModal}
      footer={
        userState?.currentRoleId === 2 && (
          <Row gutter={[16, 16]} justify="space-between">
            {record?.id ? (
              <Col>
                <Button
                  disabled={isLoading}
                  className="button-red"
                  onClick={() => handleDelete()}
                >
                  Delete
                </Button>
              </Col>
            ) : (
              <Col>
                <Button
                  className="button-outlined"
                  onClick={() => closeModal()}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
            )}

            <Col>
              <Button
                loading={isLoading}
                type="primary"
                className="button-primary"
                onClick={() => form.submit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          courseName: record?.courseName,
          expense: record?.expense,
          institution: record?.institution,
          trainingDescription: record?.trainingDescription,
          startDate: record?.id
            ? moment(record?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : record?.startDate,
          endDate: record?.id
            ? moment(record?.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : record?.endDate,
        }}
        requiredMark={true}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text h4 className="text-normal">
              {userState.currentRoleId === 2
                ? record?.id
                  ? 'Edit Training Course'
                  : 'Create Training Course'
                : 'Training Course Information'}
            </Text>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Row gutter={[16, 8]} style={{ width: '100%', margin: 0 }}>
            <Col span={24}>
              <FormInput
                title="Course Name"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="courseName"
                    rules={[
                      {
                        required: true,
                        message: 'Enter course name',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input id="courseName" placeholder="Enter course name" />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.courseName}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Institution/Instructor"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="institution"
                    rules={[
                      {
                        required: true,
                        message: 'Enter institution/instructor',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input
                        id="institution"
                        placeholder="Enter institution/instructor"
                      />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.institution}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Expense"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="expense"
                    rules={[
                      {
                        required: true,
                        message: 'Enter expense',
                      },
                      () => ({
                        validator: (_, value) => {
                          function containsOnlyNumbers(str) {
                            return /^\d+$/.test(str);
                          }
                          if (!containsOnlyNumbers(value)) {
                            return Promise.reject('Expense must be numeric');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input id="expense" placeholder="Enter Expense" />
                    ) : (
                      <Text small12>{record?.expense}</Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Start Date"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: 'Select start date',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <DatePicker
                        format="DD/MM/YYYY"
                        id="startDate"
                        placeholder="Select start date"
                      />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.startDate
                          ? moment(record?.startDate).format('DD MMM YYYY')
                          : '-'}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="End Date"
                required
                component={
                  <Col style={{ padding: 0 }}>
                    <Form.Item
                      style={{ margin: '0' }}
                      name="endDate"
                      rules={[
                        {
                          required: true,
                          message: 'Select start date',
                        },
                        () => ({
                          validator: (_, value) => {
                            if (
                              moment(form.getFieldValue('startDate')) >
                              moment(value)
                            ) {
                              return Promise.reject(
                                ' End date must be greater than start date.',
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {userState?.currentRoleId === 2 ? (
                        <DatePicker
                          format="DD/MM/YYYY"
                          id="endDate"
                          placeholder="Select end date"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {record?.endDate
                            ? moment(record?.endDate).format('DD MMM YYYY')
                            : '-'}
                        </Text>
                      )}
                    </Form.Item>
                  </Col>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Description"
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="trainingDescription"
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input
                        id="trainingDescription"
                        placeholder="Enter description"
                      />
                    ) : (
                      <Text small12>{record?.trainingDescription}</Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};
export default TrainingCourseModal;
