import React from 'react';
import { Button, Modal } from 'antd';
import { Text } from './Text';

function ConfirmModal({
  title,
  isModalOpen,
  onClose,
  handleConfirm,
  children,
}) {
  return (
    <Modal
      title={title ? <Text sub2>{title}</Text> : ''}
      open={isModalOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
        >
          <Button className="button-outlined-stroke" onClick={onClose}>
            Cancel
          </Button>

          <Button className="button-primary-round" onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      }
      centered
    >
      {children}
    </Modal>
  );
}

export default ConfirmModal;
