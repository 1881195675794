import { Button, Col, Divider, Form, Modal, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from '../../Text';
import { DatePicker, FormInput, Input, Select } from '../..';
import { AppContext } from '../../context/AppContext';
import { ProfileService } from '../../../services/api';
import Uploader from '../../../pages/components/uploader';
import moment from 'moment';
import { ROLE_ID } from '../../../utils/constants';

const CertificateModal = (props) => {
  const { record, modalVisible, closeModal, userId, getCertificates } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false);

  const { userState } = useContext(AppContext);

  const [form] = useForm();

  useEffect(() => {
    setHasFormSubmitted(false);

    form.setFieldsValue({
      certificateName: record?.certificateName,
      certificateDate: record?.id
        ? moment(record?.certificateDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : record?.certificateDate,
      certificatePicturePath: record?.certificatePicturePath,
    });
    setImage(record?.certificatePicturePath);
  }, [record]);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const isNewCertificate = !record || !record.id;
    if (isNewCertificate) {
      const payload = {
        userId: userId,
        updateBy: userId,
        certificateName: values?.certificateName,
        certificateDate: record?.id
          ? moment(values?.certificateDate).format('YYYY-MM-DD')
          : values?.certificateDate,
        certificatePicturePath: image,
      };
      await ProfileService.certificate.addCertificate(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getCertificates();
          message.success('Certificate has been created successfully');
          form.resetFields();
          setImage();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(
              `Failed to add certificate: ${response.data.message}`,
            );
          }
        },
      );
    } else {
      const payload = {
        userId: userId,
        updateBy: userId,
        certificateId: record?.id,
        certificateName: values?.certificateName,
        certificateDate: record?.id
          ? moment(values?.certificateDate).format('YYYY-MM-DD')
          : values?.certificateDate,
        certificatePicturePath: image,
      };
      await ProfileService.certificate.editCertificate(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getCertificates();
          message.success('Certificate has been edited successfully');
          form.resetFields();
          setImage();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(
              `Failed to edit certificate: ${response.data.message}`,
            );
          }
        },
      );
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await ProfileService.certificate.deleteCertificate(
      record.id,
      ({ data }) => {
        setIsLoading(false);
        getCertificates();
        message.success('Certificate has been deleted successfully');
        closeModal();
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error(
            `Failed to delete certificate: ${response.data.message}`,
          );
        }
      },
    );
  };

  return (
    <Modal
      width="600px"
      bodyStyle={{
        maxHeight: '640px',
        overflowY: 'auto',
      }}
      open={modalVisible}
      centered
      onCancel={closeModal}
      footer={
        userState?.currentRoleId === ROLE_ID.User && (
          <Col span={24}>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={12}>
                {record?.id && (
                  <Row gutter={[16, 16]} justify="start">
                    <Col>
                      <Button
                        disabled={isLoading}
                        className="button-red"
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col span={12}>
                <Row gutter={[16, 16]} justify="end">
                  <Col>
                    <Button
                      className="button-outlined"
                      onClick={() => {
                        form.resetFields();
                        closeModal();
                      }}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      loading={isLoading}
                      type="primary"
                      className="button-primary"
                      onClick={() => {
                        setHasFormSubmitted(true);
                        form.submit();
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          certificateName: record?.certificateName,
          certificateDate: record?.id
            ? moment(record?.certificateDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : record?.certificateDate,
          certificatePicturePath: record?.certificatePicturePath,
        }}
        requiredMark={true}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text h4 className="text-normal">
              {userState.currentRoleId === 2
                ? record?.id
                  ? 'Edit Certificate'
                  : 'Create Certificate'
                : 'Certificate Information'}
            </Text>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Row gutter={[16, 16]} style={{ width: '100%', margin: 0 }}>
            <Col span={18}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <FormInput
                    title="Certificate Name"
                    required
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="certificateName"
                        rules={[
                          {
                            required: true,
                            message: 'Enter certificate name',
                          },
                        ]}
                      >
                        {userState?.currentRoleId === ROLE_ID.User ? (
                          <Input
                            id="certificateName"
                            placeholder="Enter certificate name"
                          />
                        ) : (
                          <Text small12>{record?.certificateName}</Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
                <Col span={24}>
                  <FormInput
                    title="Certificate Date"
                    required
                    component={
                      <Form.Item
                        style={{ margin: '0' }}
                        name="certificateDate"
                        rules={[
                          {
                            required: true,
                            message: 'Select certificate date',
                          },
                        ]}
                      >
                        {userState?.currentRoleId === ROLE_ID.User ? (
                          <DatePicker
                            format="DD/MM/YYYY"
                            id="certificateDate"
                            placeholder="Select certificate date"
                          />
                        ) : (
                          <Text small12 className="text-normal">
                            {record?.certificateDate
                              ? moment(record?.certificateDate).format(
                                  'DD MMM YYYY',
                                )
                              : '-'}
                          </Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
              </Row>
            </Col>
            {
              <Col span={6}>
                <FormInput
                  title="Certificate Image"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="certificatePicturePath"
                      validateTrigger="callbackUpload"
                    >
                      {userState?.currentRoleId === ROLE_ID.User ? (
                        <Uploader
                          initialPath={record?.certificatePicturePath}
                          callbackUpload={(value) => {
                            setImage(value);
                          }}
                        />
                      ) : (
                        <a
                          style={{ position: 'relative' }}
                          href={record?.certificatePictureURL}
                          target="_blank"
                        >
                          <Col span={24}>
                            <img
                              src={record?.certificatePictureURL}
                              alt="avatar"
                              height={56}
                              width={56}
                              style={{ marginRight: 10 }}
                            />
                          </Col>
                        </a>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            }
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};
export default CertificateModal;
