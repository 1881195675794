import React from 'react';
import { Row, Col, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import JSZip from 'jszip';

import { Text } from './Text';
import PhotoIcon from '../assets/icons/photoIcon.svg';
import PdfIcon from '../assets/icons/pdfIcon.svg';

// api
import { AwsService } from '../services/api';
import { getFileNameFromPath } from '../services/file';

function DocumentDisplay({
  titles,
  subtitles,
  files,
  ZipName,
  primaryLayout = true,
  onLegacyDownload = async () => {
    return new Blob();
  },
}) {
  const getFileExtension = (fileName) => {
    return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
  };

  const getIcon = (fileExtension) => {
    return fileExtension === 'pdf' ? PdfIcon : PhotoIcon;
  };

  const handleDownloadFile = async (file) => {
    //Note: check if the file?.filePath containsPath or just filename. Legacy file does not have path.
    const isLegacyFile = file?.filePath === getFileNameFromPath(file?.filePath);

    if (isLegacyFile) {
      const blob = await onLegacyDownload(file?.filePath);
      const pic = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = pic;
      anchor.download = file?.filePath;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(pic);
    } else {
      AwsService.getFile(
        file?.filePath,
        ({ data }) => {
          window.open(data?.url, '_blank');
        },
        (response) => {
          if (response) {
            message.error(
              `Error: ${response.data.message}. Please try again later`,
            );
          }
        },
      );
    }
  };

  const handleDownloadFiles = async () => {
    const filePaths = files
      .filter((file) => file.length > 0)
      .flat()
      .map((file) => file.filePath);

    const areLegacyFiles = filePaths.every(
      (filePath) => filePath === getFileNameFromPath(filePath),
    );

    if (areLegacyFiles) {
      const zip = new JSZip();

      for (const filePath of filePaths) {
        const fileData = await onLegacyDownload(filePath);
        zip.file(filePath.split('/').pop(), fileData);
      }

      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(zipBlob);

      link.href = url;
      link.download = `${ZipName}.zip`;
      link.click();

      // Clean up the object URL
      window.URL.revokeObjectURL(url);
    } else {
      AwsService.getFiles(
        { filePaths },
        ({ data }) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);

          link.href = url;
          link.download = `${ZipName}.zip`;
          link.click();

          window.URL.revokeObjectURL(url);
        },
        (response) => {
          if (response) {
            message.error(
              `Error: ${response.data.message}. Please try again later`,
            );
          }
        },
      );
    }
  };

  return (
    <>
      {titles.map((title, titleIndex) => {
        const associatedFiles = files[titleIndex] || [];
        const associatedSubtitle = subtitles[titleIndex] || '';
        if (associatedFiles.length === 0) {
          return null;
        }

        return (
          <div
            key={titleIndex}
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            {/* Title */}
            <Row gutter={[16, 16]} align="middle">
              <Col xs={24} md={12}>
                <Text sub3>{title}</Text>
              </Col>

              <Col xs={24} md={12}>
                <Button
                  icon={<DownloadOutlined />}
                  style={{ float: 'right' }}
                  className="button-outlined-stroke"
                  onClick={handleDownloadFiles}
                >
                  Download All
                </Button>
              </Col>
            </Row>

            {primaryLayout ? (
              <Row gutter={[0, 16]} className="card-container-bg-grey">
                {/* Subtitle */}
                <Col span={24}>
                  <Row gutter={[16, 16]} align={'middle'}>
                    <Col xs={{ span: 12 }} md={{ span: 24 }}>
                      <Text sub3 className="text-normal">
                        {associatedSubtitle}
                      </Text>
                    </Col>
                  </Row>
                </Col>

                {/* Files */}
                {associatedFiles.map((file) => (
                  <Col span={24} key={file?.fileName}>
                    <Row gutter={[16, 16]} align={'middle'}>
                      <Col
                        span={24}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 8,
                        }}
                      >
                        <img
                          src={getIcon(getFileExtension(file?.fileName))}
                          alt="File icon"
                        />

                        <div
                          style={{
                            cursor: 'pointer',
                            whiteSpace: 'break-spaces',
                            overflow: 'hidden',
                          }}
                        >
                          <Text
                            small12
                            onClick={() => handleDownloadFile(file)}
                            className="text-normal"
                          >
                            {file?.fileName}
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            ) : (
              <Row gutter={[16, 16]}>
                {/* Subtitle */}

                {/* Files */}
                {associatedFiles.map((file, fileIndex) => (
                  <Col xs={24} key={file?.fileName}>
                    <Row gutter={[0, 16]} className="card-container-bg-grey">
                      <Col span={24}>
                        <Row gutter={[16, 16]} align={'middle'}>
                          <Col xs={24}>
                            <Text sub3 className="text-normal">
                              {subtitles[fileIndex]}
                            </Text>
                          </Col>
                        </Row>
                      </Col>

                      <Row gutter={[16, 16]} align={'middle'}>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 8,
                          }}
                        >
                          <img
                            src={getIcon(getFileExtension(file?.fileName))}
                            alt="File icon"
                          />

                          <div
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'break-spaces',
                              overflow: 'hidden',
                              lineClamp: 2,
                              maxHeight: 100,
                            }}
                          >
                            <Text
                              small12
                              onClick={() => handleDownloadFile(file)}
                              className="text-normal"
                            >
                              {file?.fileName}
                            </Text>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        );
      })}
    </>
  );
}

export default DocumentDisplay;
