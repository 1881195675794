import React, { useContext, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Col, Divider, Form, Row, Table, message } from 'antd';
import {
  ClockCircleFilled,
  EditOutlined,
  FireFilled,
  LeftOutlined,
  RightOutlined,
  SmileFilled,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { TimesheetService } from '../../services/api';
import { AppContext } from '../context/AppContext';
import { useForm } from 'antd/lib/form/Form';
import { Input } from '../Input';
import { Text } from '../Text';
import { Select } from '../Select';
import { ReactComponent as AnnualLeaveIcon } from '../../assets/icons/annualLeave.svg';
import { ReactComponent as PersonalLeaveIcon } from '../../assets/icons/personalLeave.svg';
import { ReactComponent as SickLeaveIcon } from '../../assets/icons/sickLeave.svg';
import dayjs from 'dayjs';
import moment from 'moment';
import jwt from 'jsonwebtoken';

const TimesheetList = styled(Table)`
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  min-height: 330px;
  &.ant-table-tbody > tr > td {
    border-bottom: 1px solid #eaeaea;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 6px 8px;
  }
`;

const TimesheetHourTable = (props) => {
  const {
    year,
    month,
    setSelectYear,
    setSelectMonth,
    dataProps,
    project,
    onEditModeChanged,
    isEditMode,
    isViewMode,
    onSelect,
  } = props;
  const { userState } = useContext(AppContext);
  const [totalHoursPerDay, setTotalHoursPerDay] = useState([]);
  const [totalLeavePerDay, setTotalLeavePerDay] = useState([]);
  const [totalOtPerDay, setTotalOtPerDay] = useState([]);
  const [totalHoursPerProject, setTotalHoursPerProject] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const monthList = [
    '', //calendar mode january starts at 1
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const [form] = useForm();

  const location = useLocation();

  const id = new URLSearchParams(location.search).get('id');

  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);
  const userId = id ? id : decode1.userId;

  useEffect(() => {
    getTotalHoursPerDay();
    getTotalHoursPerProject();
    getTotalLeavePerDay();
  }, [onSelect]);

  const setData = (fetchData) => {
    const days = {};
    for (let i = 1; i <= 31; i++) {
      const key = `day${i}`;
      days[key] = 0;
    }
    const data = [];
    fetchData.map((p) => {
      data.push({
        projectId: p.id,
        projectNo: p.projectNo,
        projectName: p.projectName,
        projectColorCode: p.projectColorCode,
        isProjectActive: p.isActive,
        ...days,
      });
    });
    setDataSource(data);
  };

  const pad = (num, size) => {
    return num.toString().padStart(size, '0');
  };

  const getYearList = () => {
    let currYear = dayjs().year();

    let res = [];
    for (let i = currYear; i >= 2017; i--) {
      res.push(
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>,
      );
    }

    return res;
  };

  const getMonthList = () => {
    let res = [];
    for (let i = 1; i <= 12; i++) {
      res.push(
        <Select.Option key={i} value={i}>
          {monthList[i]}
        </Select.Option>,
      );
    }

    return res;
  };

  const daysInMonth = () => {
    const yearMonth = year.toString() + '-' + pad(month, 2);
    return dayjs(yearMonth, 'YYYY-MM').daysInMonth();
  };

  const displayTimesheetHour = (minute, record, day) => {
    const key = `day${day}`;
    let data = dataProps[key];
    const isWeekend = getIsWeekend(year, month, day);
    const isHoliday = getIsHoliday(day);
    const disabled = getDisableInput(data);
    data = data.filter(
      (d) => d.type === 'task' && d.projectNo === record.projectNo,
    );
    data.push({
      workMinutes: data.reduce((total, d) => {
        return (total += d.workMinutes);
      }, 0),
      otMinutes: data.reduce((total, d) => {
        if (isWeekend || isHoliday) {
          return (total +=
            d.otHolidayWorkingMinutes + d.otHolidayNonWorkingMinutes);
        } else {
          return (total += d.otWorkingMinutes);
        }
      }, 0),
    });
    const value = data.length > 0 ? data[data.length - 1]?.workMinutes / 60 : 0;
    const otValue = data.length > 0 ? data[data.length - 1]?.otMinutes / 60 : 0;
    const timesheetId = data.map((d) => d.id)[0]
      ? data.map((d) => d.id)[0]
      : '';
    if (isWeekend || isHoliday) {
      return {
        props: {
          style: { background: isWeekend ? '#E6E6E6' : '#FFCACB' },
        },
        children: (
          <Text style={{ textAlign: 'center' }}>
            <span style={{ color: '#D62923' }}>
              {otValue > 0 ? `${otValue}` : ''}
            </span>
          </Text>
        ),
      };
    }
    if (isEditMode) {
      return (
        <Form.Item
          key={key}
          name={`${disabled}::${timesheetId}::${record.projectId}::${key}`}
          initialValue={value}
          style={{ marginBottom: 0 }}
          rules={[
            () => ({
              validator: (_, value) => {
                if (value >= 9 || (value < 0 && !disabled)) {
                  return Promise.reject('');
                } else if (
                  totalHoursPerDay[day - 1] +
                    totalLeavePerDay.totalSickLeave[day - 1] +
                    totalLeavePerDay.totalPersonalLeave[day - 1] +
                    totalLeavePerDay.totalAnnualLeave[day - 1] >
                  8
                ) {
                  return Promise.reject('');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            placeholder="hr"
            disabled={disabled || !record.isProjectActive}
            value={value}
            inputMode="numeric"
          />
        </Form.Item>
      );
    } else {
      return (
        <Text style={{ textAlign: 'center' }}>
          <span>{value > 0 ? value : otValue > 0 ? '' : '-'}</span>
          <span style={{ color: '#D62923' }}>
            {otValue > 0 ? (value == 0 ? `${otValue}` : ` + ${otValue}`) : ''}
          </span>
        </Text>
      );
    }
  };

  const displayTotalHoursPerProject = (_, record) => {
    const value = totalHoursPerProject[record.projectId];
    return (
      <Text style={{ textAlign: 'center' }}>
        <span style={{ fontWeight: 'bold' }}>{value?.totalWorkingHours}</span>
        <span style={{ color: '#D62923', fontWeight: 'bold' }}>
          {value?.totalOtHours > 0 ? ` + ${value?.totalOtHours}` : ''}
        </span>
      </Text>
    );
  };

  const getDateColumn = (dayNum) => {
    const date = year.toString() + '-' + pad(month, 2) + '-' + pad(dayNum, 2);
    const isWeekend = getIsWeekend(year, month, dayNum);
    const isHoliday = getIsHoliday(dayNum);
    return (
      <React.Fragment>
        <div
          style={{
            color: isWeekend || isHoliday ? '#f4364c' : '#000000',
            fontWeight: 'bold',
          }}
        >
          {dayjs(date, 'YYYY-MM-DD').format('ddd').toUpperCase()}
        </div>
        <div
          style={{ color: isWeekend || isHoliday ? '#f4364c' : '#737373' }}
        >{`${dayNum}`}</div>
      </React.Fragment>
    );
  };

  const getDisableInput = (data) => {
    if (data.length === 0) return false;
    const dataAddByCalendar = data.filter(
      (d) =>
        (d.type === 'task' && d.startTime) ||
        d.type === 'leave' ||
        d.type === 'holiday',
    );
    if (dataAddByCalendar.length === 0) return false;
    return true;
  };

  const getIsHoliday = (dayNum) => {
    const key = `day${dayNum}`;
    const data = dataProps[key];
    const isHoliday = data.filter((d) => d.type === 'holiday').length !== 0;
    return isHoliday;
  };

  const getIsWeekend = (year, month, day) => {
    const date = year.toString() + '-' + pad(month, 2) + '-' + pad(day, 2);
    const isWeekend =
      dayjs(date, 'YYYY-MM-DD').day() === 0 ||
      dayjs(date, 'YYYY-MM-DD').day() === 6;
    return isWeekend;
  };

  const getTotalHoursPerDay = () => {
    let totalHours = new Array(32);
    let totalOt = new Array(32);
    for (let i = 0; i < 31; i++) {
      const key = `day${i + 1}`;
      const value = dataProps[key].filter(
        (value) => value.type === 'totalMinutes',
      )[0];
      totalHours[i] = value ? value.totalWorkingMinutes / 60 : 0;
      totalOt[i] = value ? value.totalOtMinutes / 60 : 0;
    }
    totalHours = totalHours.filter((value, index) => index < daysInMonth());
    setTotalHoursPerDay(totalHours);
    setTotalOtPerDay(totalOt);
  };

  const getTotalLeavePerDay = () => {
    let totalSickLeave = new Array(32);
    let totalPersonalLeave = new Array(32);
    let totalAnnualLeave = new Array(32);
    for (let i = 0; i < 31; i++) {
      const key = `day${i + 1}`;
      const value = dataProps[key].filter((value) => value.type === 'leave')[0];
      totalSickLeave[i] = value?.leaveName == 'SICK' ? value?.leaveHour : 0;
      totalPersonalLeave[i] =
        value?.leaveName == 'PERSONAL' ? value?.leaveHour : 0;
      totalAnnualLeave[i] = value?.leaveName == 'ANNUAL' ? value?.leaveHour : 0;
    }
    totalSickLeave = totalSickLeave.filter(
      (value, index) => index < daysInMonth(),
    );
    totalPersonalLeave = totalPersonalLeave.filter(
      (value, index) => index < daysInMonth(),
    );
    totalAnnualLeave = totalAnnualLeave.filter(
      (value, index) => index < daysInMonth(),
    );
    setTotalLeavePerDay({
      totalSickLeave: totalSickLeave,
      totalPersonalLeave: totalPersonalLeave,
      totalAnnualLeave: totalAnnualLeave,
    });
  };

  const getTotalHoursPerProject = () => {
    let hoursPerProject = {};
    if (project) {
      project.map((p) => {
        const key = p.id;
        hoursPerProject[key] = {
          totalWorkingHours: 0,
          totalOtHours: 0,
        };
      });
      let totalHours = 0;
      let totalOt = 0;
      for (let i = 0; i < 31; i++) {
        const key = `day${i + 1}`;
        const timesheet = dataProps[key];
        timesheet.map((t) => {
          if (t.type === 'task' && hoursPerProject[t.projectId]) {
            hoursPerProject[t.projectId].totalWorkingHours +=
              t.workMinutes / 60;
            hoursPerProject[t.projectId].totalOtHours +=
              (t.otWorkingMinutes +
                t.otHolidayWorkingMinutes +
                t.otHolidayNonWorkingMinutes) /
              60;
            totalHours += t.workMinutes / 60;
            totalOt +=
              (t.otWorkingMinutes +
                t.otHolidayWorkingMinutes +
                t.otHolidayNonWorkingMinutes) /
              60;
          }
        });
      }
      setTotalHoursPerProject(hoursPerProject);
    }
  };

  const getPayloadForSubmitTimesheetHour = (values) => {
    let payload = {
      userId: userId,
      month: month,
      year: year,
      timesheet: [],
    };
    for (const [key, value] of Object.entries(values)) {
      const [disable, timesheetId, projectId, day] = key.split('::');
      if ((timesheetId || parseFloat(value)) && disable === 'false') {
        const workMinutes = parseFloat(value) * 60;
        const taskDate = dayjs(
          `${year}-${month}-${day.split('day')[1]}`,
          'YYYY-M-D',
        ).format('YYYY-MM-DD');
        payload.timesheet.push({
          timesheetId,
          projectId,
          taskDate,
          disable,
          workMinutes,
        });
      }
    }
    return payload;
  };

  const onSubmitTimesheetHour = (values) => {
    const payload = getPayloadForSubmitTimesheetHour(values);
    setIsLoading(true);
    TimesheetService.addTimesheetHourNewApi(
      payload,
      ({ data }) => {
        onEditModeChanged(false);
        setIsLoading(false);
      },
      (response) => {
        if (response) {
          setIsLoading(false);
          message.error(
            'Failed to submit timesheet hour: ' + response.data.message,
          );
        }
      },
    );
  };

  const onValuesChange = (changedValues, allValues) => {
    const hoursPerDay = totalHoursPerDay.map((t) => 0);
    const hoursPerProject = totalHoursPerProject;
    let totalHours = 0;
    for (const [key, value] of Object.entries(hoursPerProject)) {
      hoursPerProject[key].totalWorkingHours = 0;
      hoursPerProject[key].totalOtHours =
        totalHoursPerProject[key].totalOtHours;
    }
    //Calculate
    for (const [key, value] of Object.entries(allValues)) {
      if (value) {
        const [disable, timesheetId, projectId, day] = key.split('::');
        const index = day.split('day')[1] - 1;
        hoursPerDay[index] += parseFloat(value);
        hoursPerProject[projectId].totalWorkingHours += parseFloat(value);
        totalHours += parseFloat(value);
      }
    }
    setTotalHoursPerDay(hoursPerDay);
    setTotalHoursPerProject(hoursPerProject);
  };

  const getSummaryTimesheetHourPerDay = () => {
    const totalHours = totalHoursPerDay;
    const totalOt = totalOtPerDay;
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="left" className="normal-cell">
            <Text sub4 style={{ fontWeight: 'bold' }}>
              Total Project / Day
            </Text>
          </Table.Summary.Cell>
          {totalHours.map((value, index) => {
            const key = `day${index + 1}`;
            const isWeekend = getIsWeekend(year, month, index + 1);
            const isHoliday = getIsHoliday(index + 1);
            return (
              <Table.Summary.Cell
                key={key}
                index={key}
                align="center"
                className={
                  isWeekend
                    ? 'weekend-cell'
                    : isHoliday
                    ? 'holiday-cell'
                    : 'normal-cell'
                }
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {!(isWeekend || isHoliday) && `${totalHours[index]}`}
                  <span style={{ color: '#D62923' }}>
                    {!(isWeekend || isHoliday) && totalOt[index] > 0 && ' + '}
                  </span>
                  <span style={{ color: '#D62923' }}>
                    {totalOt[index] > 0 && `${totalOt[index]}`}
                  </span>
                </Text>
              </Table.Summary.Cell>
            );
          })}
          <Table.Summary.Cell
            index={totalHours.length + 1}
            align="center"
            className="normal-cell"
          />
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="center" className="normal-cell">
            <Text sub4 style={{ fontWeight: 'bold' }}>
              Leave
            </Text>
          </Table.Summary.Cell>
          {/*Only for map */}
          {totalHours.map((value, index) => {
            const key = `day${index + 1}`;
            return (
              <Table.Summary.Cell
                key={key}
                index={key}
                className="normal-cell"
              />
            );
          })}
          <Table.Summary.Cell
            index={totalHours.length + 1}
            align="center"
            className="normal-cell"
          >
            <Text sub4 style={{ fontWeight: 'bold' }}>
              Total Leave / Month
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="left" className="normal-cell">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <SickLeaveIcon fill="#F4364C" style={{ marginRight: 5 }} />
              <Text>Sick Leave</Text>
            </span>
          </Table.Summary.Cell>
          {totalLeavePerDay?.totalSickLeave?.map((value, index) => {
            const key = `day${index + 1}`;
            const isWeekend = getIsWeekend(year, month, index + 1);
            const isHoliday = getIsHoliday(index + 1);
            return (
              <Table.Summary.Cell
                key={key}
                index={key}
                align="center"
                className={
                  isWeekend
                    ? 'weekend-cell'
                    : isHoliday
                    ? 'holiday-cell'
                    : 'normal-cell'
                }
              >
                {!(isWeekend || isHoliday) && (
                  <Text style={{ fontWeight: 'bold' }}>
                    {value > 0 ? value : '-'}
                  </Text>
                )}
              </Table.Summary.Cell>
            );
          })}
          <Table.Summary.Cell
            index={totalLeavePerDay?.totalSickLeave?.length + 1}
            align="center"
            className="normal-cell"
          >
            <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {totalLeavePerDay?.totalSickLeave?.reduce((total, d) => {
                return (total += d);
              }, 0)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="left" className="normal-cell">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <PersonalLeaveIcon fill="#F4364C" style={{ marginRight: 5 }} />
              <Text>Personal Leave</Text>
            </span>
          </Table.Summary.Cell>
          {totalLeavePerDay?.totalPersonalLeave?.map((value, index) => {
            const key = `day${index + 1}`;
            const isWeekend = getIsWeekend(year, month, index + 1);
            const isHoliday = getIsHoliday(index + 1);
            return (
              <Table.Summary.Cell
                key={key}
                index={key}
                align="center"
                className={
                  isWeekend
                    ? 'weekend-cell'
                    : isHoliday
                    ? 'holiday-cell'
                    : 'normal-cell'
                }
              >
                {!(isWeekend || isHoliday) && (
                  <Text style={{ fontWeight: 'bold' }}>
                    {value > 0 ? value : '-'}
                  </Text>
                )}
              </Table.Summary.Cell>
            );
          })}
          <Table.Summary.Cell
            index={totalLeavePerDay?.totalPersonalLeave?.length + 1}
            align="center"
            className="normal-cell"
          >
            <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {totalLeavePerDay?.totalPersonalLeave?.reduce((total, d) => {
                return (total += d);
              }, 0)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="left" className="normal-cell">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <AnnualLeaveIcon fill="#F4364C" style={{ marginRight: 5 }} />
              <Text>Annual Leave</Text>
            </span>
          </Table.Summary.Cell>
          {totalLeavePerDay?.totalAnnualLeave?.map((value, index) => {
            const key = `day${index + 1}`;
            const isWeekend = getIsWeekend(year, month, index + 1);
            const isHoliday = getIsHoliday(index + 1);
            return (
              <Table.Summary.Cell
                key={key}
                index={key}
                align="center"
                className={
                  isWeekend
                    ? 'weekend-cell'
                    : isHoliday
                    ? 'holiday-cell'
                    : 'normal-cell'
                }
              >
                {!(isWeekend || isHoliday) && (
                  <Text style={{ fontWeight: 'bold' }}>
                    {value > 0 ? value : '-'}
                  </Text>
                )}
              </Table.Summary.Cell>
            );
          })}
          <Table.Summary.Cell
            index={totalLeavePerDay?.totalAnnualLeave?.length + 1}
            align="center"
            className="normal-cell"
          >
            <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {totalLeavePerDay?.totalAnnualLeave?.reduce((total, d) => {
                return (total += d);
              }, 0)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  useEffect(() => {
    setData(project);
    setIsLoading(false);
  }, []);

  let columns = [
    {
      title: () => (
        <Text
          sub4
          style={{ display: 'block', fontWeight: 'bold', textAlign: 'center' }}
        >
          Project
        </Text>
      ),
      key: 'projectName',
      index: 'project',
      dataIndex: 'project',
      fixed: 'left',
      width: '20%',
      align: 'Left',
      day: 0,
      render: (_, record) => (
        <Text>
          <Text
            h4
            style={{
              color: record.projectColorCode,
              lineHeight: '12px',
            }}
          >
            ●
          </Text>
          {` ${record.projectNo}-${record.projectName} ${
            !record.isProjectActive ? ' (Inactive)' : ''
          }`}
        </Text>
      ),
    },
  ];

  for (let i = 1; i <= 31; i++) {
    columns.push({
      title: getDateColumn(i),
      key: i,
      index: `day${i}`,
      dataIndex: `day${i}`,
      align: 'center',
      width: '5%',
      day: i,
      render: (minute, record) => displayTimesheetHour(minute, record, i),
    });
  }

  columns.push({
    title: () => (
      <Text sub4 style={{ fontWeight: 'bold' }}>
        Total Project / Month
      </Text>
    ),
    key: 'totalWorkingMinutes',
    index: 'totalWorkingMinutes',
    dataIndex: 'totalWorkingMinutes',
    align: 'center',
    width: '8%',
    fixed: 'right',
    day: 0,
    render: displayTotalHoursPerProject,
  });

  columns = columns.filter((column) => column.day <= daysInMonth());

  return (
    <Row
      gutter={[16, 16]}
      className="card-container"
      style={{ marginLeft: 1, marginRight: 1 }}
    >
      <Col span={24}>
        <Row
          gutter={[16, 16]}
          justify="space-between"
          align="middle"
          style={{ padding: 8 }}
        >
          <Col xs="auto">
            <Row gutter={[16, 16]}>
              <Col flex>
                <Button
                  type="primary"
                  className="button-primary"
                  onClick={() => {
                    setSelectYear(dayjs().year());
                    const date = moment({
                      year: dayjs().year(),
                      month: dayjs().month(),
                      day: dayjs().date(),
                    });
                    onSelect(date);
                  }}
                >
                  Today
                </Button>
              </Col>
              <LeftOutlined
                style={{ marginRight: 10 }}
                onClick={() => {
                  const date = moment({
                    year: month == 1 ? year - 1 : year,
                    month: month == 1 ? 11 : month - 2,
                    day: moment().date(),
                  });
                  onSelect(date);
                }}
              />

              <RightOutlined
                onClick={() => {
                  const date = moment({
                    year: month == 12 ? year + 1 : year,
                    month: month == 12 ? 0 : month,
                    day: moment().date(),
                  });

                  onSelect(date);
                }}
              />
              <Col flex>
                <Select
                  style={{ width: '120px' }}
                  value={month}
                  onChange={(value) => {
                    form.resetFields();
                    setSelectMonth(value);
                    const date = moment({
                      year: year,
                      month: value - 1,
                      day: moment().date(),
                    });
                    onSelect(date);
                  }}
                >
                  {getMonthList()}
                </Select>
              </Col>
              <Col flex>
                <Select
                  style={{ width: '80px' }}
                  value={year}
                  onChange={(value) => {
                    setSelectYear(value);
                    const date = moment({
                      year: value,
                      month: month - 1,
                      day: moment().date(),
                    });
                    onSelect(date);
                  }}
                >
                  {getYearList()}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col flex style={{ alignContent: 'center' }}>
            <Row gutter={[16, 16]}>
              <Col flex>
                <Text small12 className="text-normal">
                  <Text sub4>
                    {isViewMode ? dataProps?.user?.firstnameEn : 'You'}
                  </Text>{' '}
                  have worked in this month :{' '}
                </Text>
              </Col>
              <Col flex>
                <Text
                  sub4
                  style={{
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                    marginRight: '6px',
                  }}
                >
                  <ClockCircleFilled
                    className="text-primary"
                    style={{
                      marginRight: '4px',
                      fontSize: '18px',
                    }}
                  />
                  {Math.floor(dataProps?.totalWorkingMinutes / 60 > 0)
                    ? `${Math.floor(dataProps?.totalWorkingMinutes / 60)} h`
                    : '0 h'}
                  &nbsp;
                  {Math.floor(dataProps?.totalWorkingMinutes % 60 > 0)
                    ? `${Math.floor(dataProps?.totalWorkingMinutes % 60)} m`
                    : ''}
                </Text>
                <Text
                  sub4
                  style={{
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                  }}
                >
                  <FireFilled
                    className="text-secondary-red"
                    style={{
                      marginRight: '4px',
                      fontSize: '18px',
                    }}
                  />
                  {Math.floor(dataProps?.totalOtMinutes / 60 > 0)
                    ? `${Math.floor(dataProps?.totalOtMinutes / 60)}h`
                    : '0 h'}
                  &nbsp;
                  {Math.floor(dataProps?.totalOtMinutes % 60 > 0)
                    ? `${Math.floor(dataProps?.totalOtMinutes % 60)}m`
                    : ''}
                </Text>
              </Col>
              <Divider
                type="vertical"
                style={{ borderLeft: '2px solid black', height: '25px' }}
              />
              <Col flex>
                <Text small12 className="text-normal">
                  <Text sub4>
                    {isViewMode ? dataProps?.user?.firstnameEn : 'You'}
                  </Text>{' '}
                  have leaved in this month :{' '}
                </Text>
              </Col>
              <Col flex>
                <Text
                  sub4
                  style={{
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                  }}
                >
                  <SmileFilled
                    className="text-secondary-orange"
                    style={{
                      marginRight: '4px',
                      fontSize: '18px',
                    }}
                  />
                  {Math.floor(dataProps?.totalLeaveMinutes / 60 > 0)
                    ? `${Math.floor(dataProps?.totalLeaveMinutes / 60)}h`
                    : '0 h'}
                  &nbsp;
                  {Math.floor(dataProps?.totalLeaveMinutes % 60 > 0)
                    ? `${Math.floor(dataProps?.totalLeaveMinutes % 60)}m`
                    : ''}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form
          form={form}
          onFinish={onSubmitTimesheetHour}
          onValuesChange={onValuesChange}
        >
          <TimesheetList
            className="table-striped-rows"
            columns={columns}
            dataSource={dataSource}
            size="small"
            scroll={{ x: 2500 }}
            style={{ minHeight: '220px' }}
            summary={getSummaryTimesheetHourPerDay}
            pagination={false}
          />
        </Form>
      </Col>
      {isEditMode && (
        <Col span={24}>
          <Row gutter={[16, 16]} justify="end">
            <Col flex>
              <Button
                className="button-outlined"
                onClick={() => {
                  form.resetFields();
                  onEditModeChanged(false);
                  getTotalHoursPerDay();
                  getTotalHoursPerProject();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col flex>
              <Button
                loading={isLoading}
                type="primary"
                className="button-primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      )}
      {!isEditMode && userState?.currentRoleId == 2 && (
        <Col span={24}>
          <Row gutter={[16, 16]} justify="end">
            <Col flex>
              <Button
                icon={<EditOutlined />}
                type="primary"
                className="button-primary"
                onClick={() => {
                  onEditModeChanged(true);
                }}
              >
                Edit
              </Button>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default TimesheetHourTable;
