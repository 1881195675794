import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layout, Input, Row, Col, message, DatePicker, Progress } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EllipsisOutlined,
} from '@ant-design/icons';
import { TimesheetService } from '../services/api';
import moment from 'moment';
import profileImage from '../assets/icons/profileimage.svg';
import inProgressIcon from '../assets/icons/pending.svg';

import { Table, Select, Tag } from '../components';
import { Text } from '../components';
import { useHistory } from 'react-router-dom';
import { Circle } from '../components/Circle';

const { Content } = Layout;

const { Search } = Input;

const Timesheet = () => {
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [countComplete, setComplete] = useState(0);
  const [countInProgress, setInProgress] = useState(0);
  const [countNotstart, setNotstart] = useState(0);
  const [summaryDataByMonth, setSummaryDataByMonth] = useState([]);

  const history = useHistory();

  useEffect(() => {
    handleFetchTimesheetList();
  }, [status, search, year, month]);

  useEffect(() => {
    handleFetchSummaryTimesheet();
  }, [year, month]);

  useEffect(() => {
    calculateSummaryView(summaryDataByMonth);
  }, [summaryDataByMonth]);

  const pad = (num, size) => {
    return num.toString().padStart(size, '0');
  };

  const daysInMonth = () => {
    const yearMonth = year.toString() + '-' + pad(month, 2);
    return moment(yearMonth, 'YYYY-MM').daysInMonth();
  };

  const calculateSummaryView = (values) => {
    let complete = 0;
    let inProgress = 0;
    let notStart = 0;
    values.forEach((value) => {
      var totalPercentComplete =
        ((value?.totalWorkingMinutes + value?.totalLeaveMinutes) /
          (value?.workingDay * 8 * 60)) *
        100;
      if (totalPercentComplete === 0) {
        notStart++;
      } else if (totalPercentComplete === 100) {
        complete++;
      } else {
        inProgress++;
      }
    });
    setInProgress(inProgress);
    setComplete(complete);
    setNotstart(notStart);
  };

  const handleFetchSummaryTimesheet = async () => {
    setIsLoading(true);
    const params = {
      year: year || moment().year(),
      month: month || moment().month() + 1,
    };
    await TimesheetService.fetchTimesheetList(
      params,
      async ({ data }) => {
        setSummaryDataByMonth(data.timesheets);
      },
      (response) => {
        if (response) {
          setIsLoading(false);
          if (response.data.message != 'empty array') {
            message.error(
              'Failed to fetch timesheet data: ' + response.data.message,
            );
          }
        }
      },
    );
  };

  const handleFetchTimesheetList = async () => {
    setIsLoading(true);
    const params = {
      year: year || moment().year(),
      month: month || moment().month() + 1,
      search: search,
    };
    await TimesheetService.fetchTimesheetListNewApi(
      params,
      async ({ data }) => {
        // if (!params.search || params.search.length === 0) {
        //   setSummaryDataByMonth(data.timesheets);
        // }
        const tableData = await data.timesheets
          .filter(function (value) {
            if (status === 'Incomplete') {
              if (value.isComplete) {
                return false;
              }
            }
            if (status === 'Complete') {
              if (!value.isComplete) {
                return false;
              }
            }
            return true;
          })
          .map(async (value) => {
            if (status === 'Incomplete') {
              if (value.isComplete) {
                return false;
              }
            }
            if (status === 'Complete') {
              if (!value.isComplete) {
                return false;
              }
            }
            return value;
          });
        Promise.all(tableData).then((values) => {
          setData(values);
          setSearch(search);
          setIsLoading(false);
        });
      },
      (response) => {
        if (response) {
          setIsLoading(false);
          if (response.data.message != 'empty array') {
            message.error(
              'Failed to fetch timesheet data: ' + response.data.message,
            );
          }
        }
      },
    );
  };

  const columns = [
    {
      title: 'Emp No.',
      index: 'empCode',
      dataIndex: 'empCode',
      width: '80px',
      fixed: 'left',
      day: 0,
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => a.empCode.localeCompare(b.empCode),
      },
      render: (empCode, record) => (
        <a
          onClick={() => {
            sessionStorage.setItem('selected-mmyyyy', `${month} ${year}`);
            history.push('/timesheetDetail/?id=' + record.userId, {
              currentMonth: month,
              currentYear: year,
            });
          }}
        >
          <Text sub4 underline className="text-primary">
            {empCode}
          </Text>
        </a>
      ),
    },
    {
      title: '',
      index: 'picturePath',
      dataIndex: 'picturePath',
      width: '50px',
      day: 0,
      render: (image) => {
        return (
          <Circle
            style={{ width: '32px', height: '32px' }}
            onError={(e) => {
              e.target.src = profileImage;
            }}
            src={image ? image : profileImage}
          />
        );
      },
    },
    {
      title: 'Nickname',
      dataIndex: 'nicknameEn',
      index: 'nicknameEn',
      width: '80px',
      day: 0,
      render: (name) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 capitalize className="text-normal">
            {`${name}`}
          </Text>
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'firstnameEn',
      index: 'firstnameEn',
      day: 0,
      render: (name, record) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 capitalize className="text-normal">
            {`${record.firstnameEn} ${record.lastnameEn}`}
          </Text>
        </div>
      ),
    },
    {
      title: 'Position',
      index: 'position',
      dataIndex: 'position',
      width: '180px',
      day: 0,
      render: (position) => (
        <Text small12 className="text-normal">
          {position}
        </Text>
      ),
    },
  ];
  columns.push(
    {
      title: 'Work (Hours)',
      width: '60px',
      align: 'center',
      day: 0,
      render: (_, record) => {
        const value = record?.totalWorkingMinutes / 60;
        const otValue = record?.totalOtMinutes / 60;
        return {
          children: (
            <Text sub4 style={{ textAlign: 'center' }}>
              <span>{value > 0 ? value : '-'}</span>
            </Text>
          ),
        };
      },
    },
    {
      title: 'OT (Hours)',
      width: '60px',
      align: 'center',
      day: 0,
      render: (_, record) => {
        const value = record?.totalWorkingMinutes / 60;
        const otValue = record?.totalOtMinutes / 60;
        return {
          children: (
            <Text
              sub4
              style={{ textAlign: 'center' }}
              className="text-secondary-red"
            >
              <span>{otValue > 0 ? otValue : '-'}</span>
            </Text>
          ),
        };
      },
    },
    {
      title: 'Leave (Hours)',
      width: '60px',
      align: 'center',
      day: 0,
      render: (_, record) => {
        var leaveValue = record?.totalLeaveMinutes / 60;
        return (
          <Text sub4 className="text-normal">
            {leaveValue == 0 ? '-' : leaveValue}
          </Text>
        );
      },
    },
    {
      title: 'Progress',
      width: '80px',
      align: 'center',
      day: 0,
      render: (_, record) => {
        var totalPercentComplete = Math.floor(
          ((record?.totalWorkingMinutes + record?.totalLeaveMinutes) /
            (record?.workingDay * 8 * 60)) *
            100,
        );
        return (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text sub2 className="text-normal">
                {totalPercentComplete === 0 ? (
                  <Tag red>{totalPercentComplete}%</Tag>
                ) : totalPercentComplete < 100 ? (
                  <Tag blue>{totalPercentComplete}%</Tag>
                ) : totalPercentComplete === 100 ? (
                  <Tag green>{totalPercentComplete}%</Tag>
                ) : (
                  <Tag orange>{totalPercentComplete}%</Tag>
                )}
              </Text>
            </Col>
          </Row>
        );
      },
    },
  );

  return (
    <div>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Timesheet
                  </Text>
                </Col>
                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col flex>
                      <DatePicker
                        style={{ width: '160px', height: '100%' }}
                        onChange={(value) => {
                          setMonth(moment(value).month() + 1);
                          setYear(moment(value).year());
                        }}
                        picker="month"
                        defaultValue={moment()}
                        allowClear={false}
                        format="MMM YYYY"
                      />
                    </Col>
                    <Col flex>
                      <Select
                        style={{ width: '160px' }}
                        onChange={(value) => setStatus(value)}
                        defaultValue={'All'}
                      >
                        <Select.Option value="All">All Status</Select.Option>
                        <Select.Option value="Complete">Complete</Select.Option>
                        <Select.Option value="Incomplete">
                          Incomplete
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col
                      flex="auto"
                      style={{
                        maxWidth: 320,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Search
                        placeholder="Search"
                        allowClear={true}
                        onSearch={(value) => setSearch(value)}
                        style={{ width: '100%', maxWidth: 320 }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>
                  <Row
                    className="card-container"
                    style={{ margin: 'auto' }}
                    gutter={[16, 24]}
                  >
                    <Col
                      xs={24}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CheckCircleTwoTone
                        twoToneColor="#08B424"
                        style={{ marginRight: 5, fontSize: '16px' }}
                      />
                      <Text small12 className="text-primary-green">
                        Complete
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Text h3 className="text-normal">
                        {summaryDataByMonth.length === 0 ||
                        !summaryDataByMonth.length
                          ? 0
                          : parseFloat(
                              (
                                (countComplete / summaryDataByMonth.length) *
                                100
                              ).toFixed(2),
                            )}{' '}
                        %
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8}>
                  <Row
                    className="card-container"
                    style={{ margin: 'auto' }}
                    gutter={[16, 24]}
                  >
                    <Col
                      xs={24}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div className="icon-container">
                        <EllipsisOutlined
                          style={{ color: '#4A80C9', fontSize: '14px' }}
                        />
                      </div>
                      <Text small12 className="text-tertiary-blue">
                        In Progress
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Text h3 className="text-normal">
                        {summaryDataByMonth.length === 0 ||
                        !summaryDataByMonth.length
                          ? 0
                          : parseFloat(
                              (
                                (countInProgress / summaryDataByMonth.length) *
                                100
                              ).toFixed(2),
                            )}{' '}
                        %
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8}>
                  <Row
                    className="card-container"
                    style={{ margin: 'auto' }}
                    gutter={[16, 24]}
                  >
                    <Col
                      xs={24}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CloseCircleTwoTone
                        twoToneColor="#F4364C"
                        style={{ marginRight: 5, fontSize: '16px' }}
                      />
                      <Text small12 className="text-secondary-red">
                        Not Started
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Text
                        h3
                        className="text-normal"
                        style={{ marginTop: '24px' }}
                      >
                        {summaryDataByMonth.length === 0 ||
                        !summaryDataByMonth.length
                          ? 0
                          : parseFloat(
                              100 -
                                ((countInProgress / summaryDataByMonth.length) *
                                  100 +
                                  (countComplete / summaryDataByMonth.length) *
                                    100),
                            ).toFixed(2)}{' '}
                        %
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                className="table-striped-rows"
                columns={columns.filter((value) => value.day <= daysInMonth())}
                dataSource={data}
                size="small"
                loading={isLoading}
                scroll={{ x: 500, y: 'calc(100vh - 260px)' }}
                pagination={{
                  position: ['bottomRight'],
                  pageSizeOptions: [10, 20, 30, 40],
                  showSizeChanger: true,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default Timesheet;
