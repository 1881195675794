import React from 'react';
import { InputNumber as AntdInputNumber } from 'antd';
import styled from 'styled-components';
import numbro from 'numbro';

export const StyledInputNumber = styled(AntdInputNumber)`
  width: 100%;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
  font-size: 12px;
  align-items: center;
  text-align-last: ${(props) => (props.align === 'right' ? 'right' : 'left')};

  .ant-input-number-input {
    caret-color: transparent; /* Hides the blinking cursor */
    text-align: ${(props) => (props.align === 'right' ? 'right' : 'left')};
  }

  .ant-input-number-input::placeholder {
    text-align: ${(props) =>
      props.align === 'right'
        ? 'right'
        : 'left'}; /* Explicitly left-aligns the placeholder text */
  }

  .ant-input-number-input:focus {
    caret-color: auto; /* Shows the cursor when focused */
  }
`;

const InputNumber = ({ align, ...props }) => {
  const handleKeyDown = (event) => {
    const { key, target } = event;
    const value = target.value;
    const [integerPart, decimalPart] = value.split('.');

    if (key === '.' && value.includes('.')) {
      event.preventDefault();
    }

    if (decimalPart && decimalPart.length >= 2 && /^\d$/.test(key)) {
      event.preventDefault();
    }

    if (
      key !== 'Backspace' &&
      key !== 'Tab' &&
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight' &&
      key !== '.' &&
      !/^\d$/.test(key)
    ) {
      event.preventDefault();
    }
  };

  const formatter = (value) =>
    value &&
    numbro(value).format({
      thousandSeparated: true,
    });

  return (
    <StyledInputNumber
      {...props}
      align={align}
      controls={false}
      formatter={formatter}
      onKeyDown={handleKeyDown}
    />
  );
};

export default InputNumber;
