import React from 'react';
import { Alert } from 'antd';
import styled from 'styled-components';

const getBackgroundColor = (type) => {
  switch (type) {
    case 'info':
      return '#4A80C914';
    default:
      return 'var(--white)';
  }
};

const getTextColor = (type) => {
  switch (type) {
    case 'info':
      return 'var(--finished)';
    default:
      return 'var(--grey)';
  }
};

const StyledAlert = styled(Alert)`
  &.ant-alert {
    border: 0px;
    background-color: ${(props) => getBackgroundColor(props.type)};
    color: ${(props) => getTextColor(props.type)};

    .ant-alert-message {
      color: ${(props) => getTextColor(props.type)};
    }

    .ant-alert-icon {
      color: ${(props) => getTextColor(props.type)};
    }
  }
`;

const TextSupporter = ({ text, type, showIcon }) => {
  return <StyledAlert message={text} type={type} showIcon={showIcon} />;
};

export default TextSupporter;
