import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { Text } from './';

function FormInputNew(props) {
  const { title, subtitle, required, component, white } = props;

  return (
    <Row gutter={[8, 8]}>
      <div>
        <Col span={24}>
          {white ? (
            <Text sub4 className="text-white">
              {title}
            </Text>
          ) : (
            <Text sub4 className="text-normal">
              {title}
            </Text>
          )}
          {required && (
            <Text sub4 className="text-require">
              *
            </Text>
          )}
        </Col>

        {subtitle && (
          <Col span={24}>
            <Text small12 className="text-grey" style={{ marginTop: '0px' }}>
              {subtitle}
            </Text>
          </Col>
        )}
      </div>

      <Col span={24}>{component}</Col>
    </Row>
  );
}

export default FormInputNew;
