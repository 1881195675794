import React, { useState, useEffect, useContext } from 'react';
import {
  Layout,
  Modal,
  message,
  Row,
  Col,
  Divider,
  Avatar,
  Button,
  Form,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { decode } from 'jsonwebtoken';

import { AppContext } from '../../components/context/AppContext';
import { Input } from '../../components';
import { Text } from '../../components/Text';
import { Tag } from '../../components/Tag';
import InputNumber from '../../components/InputNumber';
import DocumentDisplay from '../../components/DocumentDisplay';
import defaultProfileImage from '../../assets/icons/profileimage.svg';
import localAtmIcon from '../../assets/icons/localAtm.svg';
import { ROLE_ID } from '../../utils/constants';

// api
import {
  UserInfoService,
  DepartmentService,
  PositionService,
  ClaimService,
} from '../../services/api';

const { Content } = Layout;

function BenefitClaimDetailDialog({
  isModalOpen,
  onClose,
  record,
  fetchClaimRequests,
}) {
  const [form] = useForm();
  const { userState } = useContext(AppContext);
  const accessToken = sessionStorage.getItem('access-token');
  const userId = decode(accessToken).userId;

  const [userInfo, setUserInfo] = useState(null);
  const [latestRequestStatus, setLatestRequestStatus] = useState(null);
  const [latestRequestStatusUpdatedBy, setLatestRequestStatusUpdatedBy] =
    useState(null);
  const [invoice, setInvoice] = useState(null);
  const [relatedDocuments, setRelatedDocuments] = useState(null);
  const [department, setDepartment] = useState(null);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    fetchUserInfoUnique();
    fetchLatestRequestStatus();
    fetchInvoice();
    fetchRelatedDocuments();
  }, []);

  useEffect(() => {
    latestRequestStatus && fetchLatestRequestStatusUpdatedBy();
  }, [latestRequestStatus]);

  useEffect(() => {
    userInfo && fetchDepartment();
    userInfo && fetchPosition();
  }, [userInfo]);

  const fetchUserInfoUnique = () => {
    UserInfoService.fetchUserInfoUnique(
      record.userId,
      ({ data }) => {
        setUserInfo(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching user info: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchLatestRequestStatus = () => {
    ClaimService.fetchLatestClaimRequestStatus(
      record.id,
      ({ data }) => {
        setLatestRequestStatus(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching latest request status: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchLatestRequestStatusUpdatedBy = () => {
    UserInfoService.fetchUserInfoUnique(
      latestRequestStatus?.requestStatusUpdatedBy,
      ({ data }) => {
        setLatestRequestStatusUpdatedBy(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching latest request status updated by: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchInvoice = () => {
    ClaimService.fetchClaimRequestInvoice(
      record.id,
      ({ data }) => {
        setInvoice(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching invoice: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchRelatedDocuments = () => {
    ClaimService.fetchClaimRequestDocuments(
      record.id,
      ({ data }) => {
        setRelatedDocuments(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching related documents: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchDepartment = () => {
    DepartmentService.fetchDepartmentById(
      userInfo?.departmentId,
      ({ data }) => {
        setDepartment(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching department: ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchPosition = () => {
    PositionService.fetchPositionById(
      userInfo?.positionId,
      ({ data }) => {
        setPosition(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Error while fetching position: ${response.data.message}`,
          );
        }
      },
    );
  };

  const handleRejectClaimRequest = () => {
    let remark = form.getFieldValue('remark');

    if (!remark) {
      form.setFields([
        { name: 'amount', errors: [] },
        {
          name: 'remark',
          errors: ['Please input reject reason.'],
        },
      ]);

      return;
    }

    ClaimService.createClaimRequestStatus(
      {
        claimRequestId: record.id,
        requestStatusTypeId: 300,
        requestStatusUpdatedBy: userId,
        remark,
      },
      () => {
        message.success('Claim has been updated successfully.');
        fetchClaimRequests();
        onClose();
      },
      (response) => {
        if (response) {
          message.error(message.error(`Error: ${response.data.message}`));
        }
      },
    );
  };

  const handleApproveClaimRequest = () => {
    let amount = form.getFieldValue('amount');
    let remark = form.getFieldValue('remark');

    if (!remark) {
      remark = '-';
      form.setFields([{ name: 'remark', errors: [] }]);
    }

    if (!record?.amount && !amount) {
      form.setFields([
        {
          name: 'amount',
          errors: ['Please input amount.'],
        },
      ]);

      return;
    } else {
      ClaimService.updateClaimRequest(
        {
          id: record.id,
          amount,
        },
        () => {},
        (response) => {
          if (response) {
            message.error(message.error(`Error: ${response.data.message}`));
          }
        },
      );
    }

    ClaimService.createClaimRequestStatus(
      {
        claimRequestId: record.id,
        requestStatusTypeId: 200,
        requestStatusUpdatedBy: userId,
        remark,
      },
      () => {
        message.success('Claim has been updated successfully.');
        fetchClaimRequests();
        onClose();
      },
      (response) => {
        if (response) {
          message.error(message.error(`Error: ${response.data.message}`));
        }
      },
    );
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={onClose}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      centered
      width={'40vw'}
      bodyStyle={{
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
    >
      <Layout className="bg-white">
        <Form form={form}>
          <Content
            style={{
              padding: '24px',
              overflow: 'initial',
              gap: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* User Info*/}
            <Row gutter={[16, 16]} align="middle">
              {/* Profile Image*/}
              <Col span={24}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  <Avatar
                    style={{ margin: '0 3%' }}
                    size={32}
                    src={
                      userInfo && userInfo.picturePath
                        ? userInfo.picturePath
                        : defaultProfileImage
                    }
                  />

                  {/* User Name*/}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Text h3 className="text-normal">
                      {userInfo?.firstnameEn + ' ' + userInfo?.lastnameEn}
                    </Text>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Department, Position*/}
            <Row gutter={[16, 16]} align="middle">
              {/* Department */}
              <Col xs={24} sm={12} md={5}>
                <Text
                  sub3
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  Department
                </Text>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Text
                  body14
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  {department?.name}
                </Text>
              </Col>

              {/* Position */}
              <Col xs={24} sm={12} md={4}>
                <Text
                  sub3
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  Position
                </Text>
              </Col>

              <Col xs={24} sm={12} md={7}>
                <Text
                  body14
                  className="text-normal"
                  style={{ wordWrap: 'break-word' }}
                >
                  {position?.positionName}
                </Text>
              </Col>
            </Row>

            <Divider style={{ margin: '0' }} />

            {/* 1st Claim Detail (claim type, claim no., event date, amount) */}
            {/* Claim Type and Claim No. */}
            <Row gutter={[16, 16]} align="middle">
              <Col span={2}>
                <img
                  src={localAtmIcon}
                  style={{ verticalAlign: 'text-bottom' }}
                />
              </Col>

              <Col span={12}>
                <Text sub3 className="text-normal">
                  {record?.claimTypeName}
                </Text>
              </Col>

              <Col span={10}>
                <Text
                  sub3
                  className="text-secondary-red"
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  {record?.transactionNumber}
                </Text>
              </Col>
            </Row>

            {/* Event Date */}
            <Row gutter={[16, 16]} align="middle">
              <Col span={12}>
                <Text
                  body14
                  className="text-normal"
                  style={{ display: 'flex', justifyContent: 'start' }}
                >
                  Event Date
                </Text>
              </Col>

              <Col span={12}>
                <Text
                  sub3
                  className="text-normal"
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  {dayjs(record?.eventDate).format('DD/MM/YYYY')}
                </Text>
              </Col>
            </Row>

            {/* Invoice Number */}
            {invoice && (
              <Row gutter={[16, 16]} align="middle">
                <Col span={12}>
                  <Text
                    body14
                    className="text-normal"
                    style={{ display: 'flex', justifyContent: 'start' }}
                  >
                    Invoice/Receipt Number
                  </Text>
                </Col>

                <Col span={12}>
                  <Text
                    sub3
                    className="text-normal"
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    {invoice?.invoiceNumber}
                  </Text>
                </Col>
              </Row>
            )}

            {/* Amount */}
            <Row gutter={[16, 16]} align="middle">
              <Col span={12}>
                <Text
                  body14
                  className="text-normal"
                  style={{ display: 'flex', justifyContent: 'start' }}
                >
                  Amount
                </Text>
              </Col>

              <Col span={12}>
                {userState.currentRoleId === ROLE_ID.Hr ||
                userState.currentRoleId === ROLE_ID.User ||
                record?.amount ||
                record?.status !== 'Pending' ? (
                  <Text
                    sub3
                    className="text-normal"
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    {record?.amount
                      ? record?.amount.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + ' Baht'
                      : '-'}
                  </Text>
                ) : (
                  <Form.Item name="amount" style={{ margin: 0 }}>
                    <InputNumber
                      align={'right'}
                      min={0}
                      placeholder="Enter amount"
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Divider style={{ margin: '0' }} />

            {/* 2nd Claim Detail (reason, related document file) */}
            {/* Reason */}
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Text body14 className="text-normal">
                  Reason
                </Text>
              </Col>

              <Col span={24}>
                <Text sub3 className="text-normal">
                  {record?.description}
                </Text>
              </Col>
            </Row>

            <Divider style={{ margin: '0' }} />

            {/* Related Document File */}
            <DocumentDisplay
              titles={['Document', 'Documents']}
              subtitles={['Invoice File', 'Related Documents']}
              files={[invoice ? [invoice] : [], relatedDocuments]}
              ZipName={`${record.transactionNumber}_${dayjs().format(
                'DDMMYYYY_hh.mm',
              )}`}
            />

            <Divider style={{ margin: '0' }} />

            {/* 3rd Claim Detail (status) */}
            {/* Status */}
            <Row gutter={[16, 16]} align="middle">
              <Col span={12}>
                <Text body14 className="text-normal">
                  Status
                </Text>
              </Col>

              <Col span={12}>
                <Text
                  small12
                  className="text-normal"
                  style={{ float: 'right' }}
                >
                  {record.status === 'Pending' ? (
                    <Tag yellow>{record.status}</Tag>
                  ) : record.status === 'Approved' ? (
                    <Tag green>{record.status}</Tag>
                  ) : record.status === 'Rejected' ? (
                    <Tag red>{record.status}</Tag>
                  ) : record.status === 'Canceled' ? (
                    <Tag grey>{record.status}</Tag>
                  ) : null}
                </Text>
              </Col>
            </Row>

            <Divider style={{ margin: '0' }} />

            {/* 4th Claim Detail (status detail, depend on status) */}
            {/* Approved and Rejectd by */}
            {(record.status === 'Approved' || record.status === 'Rejected') && (
              <Row gutter={[16, 16]} align="middle">
                <Col span={12}>
                  <Text body14 className="text-normal">
                    {record.status === 'Approved'
                      ? 'Approved by'
                      : 'Rejected by'}
                  </Text>
                </Col>

                <Col span={12} style={{ textAlign: 'right' }}>
                  <Avatar
                    size={32}
                    src={
                      latestRequestStatusUpdatedBy &&
                      latestRequestStatusUpdatedBy.picturePath
                        ? latestRequestStatusUpdatedBy.picturePath
                        : defaultProfileImage
                    }
                    style={{ margin: '0 3%' }}
                  />

                  <Text sub3 className="text-normal">
                    {latestRequestStatusUpdatedBy &&
                      `${latestRequestStatusUpdatedBy?.firstnameEn} ${latestRequestStatusUpdatedBy?.lastnameEn}`}
                  </Text>
                </Col>
              </Row>
            )}

            {/* Status Date */}
            <Row gutter={[16, 16]} align="middle">
              <Col span={12}>
                <Text body14 className="text-normal">
                  Status Date
                </Text>
              </Col>

              <Col span={12}>
                <Text sub3 className="text-normal" style={{ float: 'right' }}>
                  {latestRequestStatus &&
                    dayjs(latestRequestStatus?.updatedAt).format(
                      'DD MMMM YYYY, HH:mm',
                    )}
                </Text>
              </Col>
            </Row>

            {(record.status === 'Approved' || record.status === 'Rejected') && (
              // Hr Reason
              <Row gutter={[16, 0]} align="middle">
                <Col span={24}>
                  <Text body14 className="text-normal">
                    Approve/Reject Reason
                  </Text>
                </Col>

                <Col span={24}>
                  <Text sub3 className="text-normal">
                    {latestRequestStatus?.remark
                      ? latestRequestStatus?.remark
                      : '-'}
                  </Text>
                </Col>
              </Row>
            )}

            {/* Action for Hr manager and Admin */}
            {(userState.currentRoleId === ROLE_ID.HrManager ||
              userState.currentRoleId === ROLE_ID.Admin) &&
              record.status === 'Pending' && (
                <Row gutter={[8, 8]}>
                  <Divider style={{ margin: '0' }} />

                  <Col span={24}>
                    <Text body14 className="text-normal">
                      Approve/Reject Reason
                    </Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="remark">
                      <Input
                        placeholder="Enter approve/reject reason"
                        size="large"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '8px',
                    }}
                  >
                    <Button
                      type="default"
                      icon={<CloseCircleOutlined />}
                      width="97px"
                      className="button-red"
                      onClick={handleRejectClaimRequest}
                    >
                      Reject
                    </Button>

                    <Button
                      type="primary"
                      icon={<CheckCircleOutlined />}
                      width="110px"
                      className="button-primary"
                      onClick={handleApproveClaimRequest}
                    >
                      Approve
                    </Button>
                  </Col>
                </Row>
              )}
          </Content>
        </Form>
      </Layout>
    </Modal>
  );
}

export default BenefitClaimDetailDialog;
