import React, { useContext } from 'react';
import { Layout } from 'antd';

import { ROLE_ID } from '../../utils/constants';
import { AppContext } from '../../components/context/AppContext';
import { Text } from '../../components';
import Tabs from '../../components/tabs';
import SalaryDownloadPage from './salary';
import TaxCertificateDownloadPage from './taxCertificate';

const { Content } = Layout;

const PaySlip = () => {
  const { userState } = useContext(AppContext);
  const items = [
    {
      key: '1',
      label: <Text sub3>Pay Slip</Text>,
      children: <SalaryDownloadPage />,
    },
    {
      key: '2',
      label: <Text sub3>Tax Certificate</Text>,
      children: <TaxCertificateDownloadPage />,
    },
  ];

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </Content>
      </Layout>
    </div>
  );
};
export default PaySlip;
