import { Button, Col, Divider, Form, Modal, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from '../../Text';
import { Checkbox, DatePicker, FormInput, Input } from '../..';
import { AppContext } from '../../context/AppContext';
import moment from 'moment';
import { ProfileService } from '../../../services/api';

const WorkExperienceModal = (props) => {
  const { record, modalVisible, closeModal, userId, getWorkExperience } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentWorkplace, setIsCurrentWorkplace] = useState(false);

  const { userState } = useContext(AppContext);

  const [form] = useForm();

  useEffect(() => {
    const formValues = {
      position: record?.position,
      companyName: record?.companyName,
      startDate: record?.id
        ? moment(record?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : record?.startDate,
      endDate:
        record?.id && record?.endDate
          ? moment(record?.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          : null,
      description: record?.description,
    };

    if (record) {
      formValues.isCurrentWorkplace = record?.endDate === null;
      setIsCurrentWorkplace(record?.endDate == null);
    } else {
      formValues.isCurrentWorkplace = false;
      setIsCurrentWorkplace(false);
    }

    form.setFieldsValue(formValues);
  }, [record]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const isNewWorkExp = !record || !record.id;
    if (isNewWorkExp) {
      const payload = {
        userId: userId,
        updateBy: userId,
        companyName: values.companyName,
        position: values.position,
        startDate: moment(values.startDate).format('YYYY-MM-DD'),
        endDate: values?.endDate
          ? moment(values.endDate).format('YYYY-MM-DD')
          : null,
        description: values.description,
      };
      await ProfileService.workExperience.addWorkExperience(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getWorkExperience();
          message.success('Work experience has been created successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(
              `Failed to add work experience: ${response.data.message}`,
            );
          }
        },
      );
    } else {
      const payload = {
        userId: userId,
        updateBy: userId,
        workExperienceId: record.id,
        companyName: values.companyName,
        position: values.position,
        startDate: moment(values.startDate).format('YYYY-MM-DD'),
        endDate: values.endDate
          ? moment(values.endDate).format('YYYY-MM-DD')
          : null,
        description: values.description,
      };
      await ProfileService.workExperience.editWorkExperience(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getWorkExperience();
          message.success('Work experience has been edited successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(
              `Failed to edit work experience: ${response.data.message}`,
            );
          }
        },
      );
    }

    setIsCurrentWorkplace(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await ProfileService.workExperience.deleteWorkExperience(
      record.id,
      ({ data }) => {
        setIsLoading(false);
        getWorkExperience();
        message.success('Work experience has been deleted successfully');
        closeModal();
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error(
            `Failed to delete work experience: ${response.data.message}`,
          );
        }
      },
    );
  };

  return (
    <Modal
      width="500px"
      bodyStyle={{
        maxHeight: '540px',
        overflowY: 'auto',
      }}
      open={modalVisible}
      centered
      onCancel={closeModal}
      footer={
        userState?.currentRoleId === 2 && (
          <Row gutter={[16, 16]} justify="space-between">
            {record?.id ? (
              <Col>
                <Button
                  disabled={isLoading}
                  className="button-red"
                  onClick={() => handleDelete()}
                >
                  Delete
                </Button>
              </Col>
            ) : (
              <Col>
                <Button
                  className="button-outlined"
                  onClick={() => closeModal()}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
            )}

            <Col>
              <Button
                loading={isLoading}
                type="primary"
                className="button-primary"
                onClick={() => form.submit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          position: record?.position,
          companyName: record?.companyName,
          startDate: record?.id
            ? moment(record?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : record?.startDate,
          endDate: record?.id
            ? moment(record?.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : record?.endDate,
          description: record?.description,
        }}
        requiredMark={true}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text h4 className="text-normal">
              {userState.currentRoleId === 2
                ? record?.id
                  ? 'Edit Work Experience'
                  : 'Create Work Experience'
                : 'Work Experience Information'}
            </Text>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Row gutter={[16, 16]} style={{ width: '100%', margin: 0 }}>
            <Col span={24}>
              <FormInput
                title="Position Name"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="position"
                    rules={[
                      {
                        required: true,
                        message: 'Enter position name',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input
                        style={{ width: '100%' }}
                        id="position"
                        placeholder="Enter position name"
                      />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.position}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Company Name"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: 'Enter company name',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input
                        style={{ width: '100%' }}
                        id="companyName"
                        placeholder="Enter company name"
                      />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.companyName}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Start Job Date"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: 'Select start job date',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <DatePicker
                        format="DD/MM/YYYY"
                        id="startDate"
                        placeholder="Select start job date"
                      />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.startDate
                          ? moment(record?.startDate).format('DD MMM YYYY')
                          : '-'}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="End Job Date"
                required
                component={
                  <>
                    <Form.Item
                      style={{ margin: '0' }}
                      name="endDate"
                      rules={[
                        () => ({
                          validator: (_, value) => {
                            if (!value && !isCurrentWorkplace) {
                              return Promise.reject(
                                'Please input end job date.',
                              );
                            }
                            if (
                              moment(form.getFieldValue('startDate')) >
                              moment(value)
                            ) {
                              return Promise.reject(
                                ' End job date must be greater than start job date.',
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {userState?.currentRoleId === 2 ? (
                        <DatePicker
                          disabled={isCurrentWorkplace}
                          format="DD/MM/YYYY"
                          id="endDate"
                          placeholder="Select end job date"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {record?.endDate
                            ? moment(record?.endDate).format('DD MMM YYYY')
                            : '-'}
                        </Text>
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ margin: '0' }}
                      name="isCurrentWorkplace"
                      valuePropName="checked"
                    >
                      <Checkbox
                        id="isCurrentWorkplace"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setIsCurrentWorkplace(isChecked);

                          const endDateValue = isChecked
                            ? null
                            : record?.endDate
                            ? moment(record.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                            : null;

                          form.setFieldValue('endDate', endDateValue);
                          form.validateFields(['endDate']);
                        }}
                      >
                        <Text small12>Currently working</Text>
                      </Checkbox>
                    </Form.Item>
                  </>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Description"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: 'Enter description',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input.TextArea
                        placeholder="Enter description"
                        rows={5}
                      />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.description}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};
export default WorkExperienceModal;
