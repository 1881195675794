import React from 'react';
import {
  Button,
  Col,
  Divider,
  Empty,
  Modal,
  Popconfirm,
  Row,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { Text } from '../Text';
import {
  ClockCircleFilled,
  FireFilled,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SmileFilled,
  CalendarFilled,
} from '@ant-design/icons';
import { TimesheetService } from '../../services/api';

import { ReactComponent as AnnualLeaveIcon } from '../../assets/icons/annualLeave.svg';
import { ReactComponent as PersonalLeaveIcon } from '../../assets/icons/personalLeave.svg';
import { ReactComponent as SickLeaveIcon } from '../../assets/icons/sickLeave.svg';

const TaskModal = (props) => {
  const {
    openViewModal,
    setOpenViewModal,
    checkHoliday,
    selectedDateData,
    setSelectedTask,
    handleOpenCreateTaskModal,
    handleOpenEditTaskModal,
    handleOpenCreateOtModal,
    handleOpenEditOtModal,
    selectedDate,
    isViewMode,
    taskList,
  } = props;

  const handleDeleteTask = async (item) => {
    let success = true;

    await TimesheetService.deleteTimesheetNewApi(
      item?.id,
      async ({ data }) => {},
      (response) => {
        if (response && response.status === 400) {
          message.error('Delete Error');
          success = false;
        }
      },
    );
    if (success) {
      message.success('Delete Success');
      setOpenViewModal(false);
    }
  };

  return (
    <Modal
      width="480px"
      bodyStyle={{
        maxHeight: '800px',
        overflowY: 'auto',
      }}
      open={openViewModal}
      centered
      footer={null}
      maskClosable
      onCancel={(e) => setOpenViewModal(false)}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row align="middle" gutter={[16, 16]}>
            <Col>
              <Text
                h4
                className={
                  checkHoliday(selectedDate)
                    ? 'text-secondary-red'
                    : 'text-normal'
                }
              >
                {dayjs(selectedDate).format('DD/MM/YYYY')}
              </Text>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: 0 }} />
        <Col span={24} style={{ maxHeight: '540px', overflowY: 'auto' }}>
          {selectedDateData?.length > 0 ? (
            <Row gutter={[16, 16]}>
              {selectedDateData?.map((item, index) => {
                if (item.type === 'holiday') {
                  return (
                    <Col key={'index' + index} span={24}>
                      <div
                        style={{
                          padding: '16px',
                          background: '#D200EB',
                          borderRadius: '4px',
                        }}
                      >
                        <Row
                          gutter={[16, 16]}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Col>
                            <CalendarFilled className="text-white" />
                          </Col>
                          <Col style={{ flex: 1 }}>
                            <Text sub4 className="text-white">
                              {item.dateName}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                } else if (item.type === 'leave') {
                  return (
                    <Col key={'index' + index} span={24}>
                      <div
                        style={{
                          padding: '16px',
                          background: '#F46F36',
                          borderRadius: '4px',
                        }}
                      >
                        <Row
                          gutter={[16, 16]}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Col>
                            {item.leaveName == 'PERSONAL' ? (
                              <PersonalLeaveIcon
                                fill="white"
                                style={{
                                  marginRight: 5,
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : item.leaveName == 'ANNUAL' ? (
                              <AnnualLeaveIcon
                                fill="white"
                                style={{
                                  marginRight: 5,
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : (
                              <SickLeaveIcon
                                fill="white"
                                style={{
                                  marginRight: 5,
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            )}
                          </Col>
                          <Col style={{ flex: 1 }}>
                            <Row gutter={[8, 8]}>
                              <Col span={24}>
                                <div>
                                  <Text small12 className="text-white">
                                    {`${dayjs(item.startTime).format(
                                      'HH:mm',
                                    )} - ${dayjs(item.endTime).format(
                                      'HH:mm',
                                    )}`}
                                  </Text>
                                </div>
                                <Text sub4 className="text-white">
                                  {item.leaveName} LEAVE
                                </Text>
                              </Col>
                              <Divider style={{ width: '100%', margin: 0 }} />
                              <Col span={24}>
                                {item.purpose ? (
                                  <Text small12 className="text-white">
                                    {item.purpose}
                                  </Text>
                                ) : (
                                  <Text small12 className="text-white">
                                    No Purpose
                                  </Text>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                } else if (item.type === 'task') {
                  return (
                    <Col key={'index' + index} span={24}>
                      <div
                        style={{
                          padding: '16px',
                          backgroundColor: `${
                            item.otHolidayNonWorkingMinutes +
                              item.otHolidayWorkingMinutes +
                              item.otWorkingMinutes >
                            0
                              ? 'rgba(255, 228, 232,  1)'
                              : 'rgba(215, 237, 255, 1)'
                          }`,
                          borderRadius: '4px',
                        }}
                      >
                        <Row
                          gutter={[16, 16]}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Col>
                            <Text
                              h4
                              style={{
                                color: item.projectColorCode,
                                lineHeight: '12px',
                              }}
                            >
                              ●
                            </Text>
                          </Col>
                          <Col style={{ flex: 1 }}>
                            <Row gutter={[8, 8]} justify="space-between">
                              <Col span={16}>
                                {item.startTime && item.endTime ? (
                                  <div>
                                    <Text small12 className="text-normal">
                                      {`${dayjs(item.startTime).format(
                                        'HH:mm',
                                      )} - ${dayjs(item.endTime).format(
                                        'HH:mm',
                                      )}`}
                                    </Text>
                                  </div>
                                ) : (
                                  <div>
                                    <Text small12 className="text-normal">
                                      {`${item.workMinutes / 60} ${
                                        item.workMinutes / 60 > 1
                                          ? ' hrs'
                                          : ' hr'
                                      }`}
                                    </Text>
                                  </div>
                                )}

                                <div>
                                  <Text sub4 className="text-normal">
                                    {item.projectNo}
                                    {' - '}
                                    {item.projectName}
                                  </Text>
                                </div>
                              </Col>
                              <Col
                                span={8}
                                style={{
                                  textAlign: 'right',
                                }}
                              >
                                <Text small12 style={{ color: '#52575C' }}>{`${
                                  taskList.filter(
                                    (task) => task.id === item.taskTypeId,
                                  )[0]?.taskTypeName ?? ''
                                }`}</Text>
                                {item.otHolidayNonWorkingMinutes > 0 ||
                                item.otHolidayWorkingMinutes > 0 ||
                                item.otWorkingMinutes > 0 ? (
                                  <FireFilled
                                    className="text-secondary-red"
                                    style={{
                                      paddingLeft: '10px',
                                      fontSize: '16px',
                                    }}
                                  />
                                ) : (
                                  <ClockCircleFilled
                                    className="text-primary-blue"
                                    style={{
                                      paddingLeft: '10px',
                                      fontSize: '16px',
                                    }}
                                  />
                                )}
                              </Col>
                              <Divider style={{ width: '100%', margin: 0 }} />
                              <Col span={24}>
                                {item.taskDescription ? (
                                  <Text
                                    small12
                                    className="text-normal"
                                    style={{ whiteSpace: 'pre-line' }}
                                  >
                                    {item.taskDescription}
                                  </Text>
                                ) : (
                                  <Text small12 className="text-grey">
                                    No Description
                                  </Text>
                                )}
                              </Col>
                              {!isViewMode &&
                                item.startTime &&
                                item.endTime && (
                                  <Col span={24}>
                                    <Row gutter={[16, 16]} justify="end">
                                      <Col>
                                        <EditOutlined
                                          className="text-normal"
                                          style={{ fontSize: '16px' }}
                                          onClick={(e) => {
                                            setSelectedTask(item);
                                            setOpenViewModal(false);
                                            if (
                                              item.otHolidayNonWorkingMinutes >
                                                0 ||
                                              item.otHolidayWorkingMinutes >
                                                0 ||
                                              item.otWorkingMinutes > 0
                                            )
                                              handleOpenEditOtModal();
                                            else handleOpenEditTaskModal();
                                          }}
                                        />
                                      </Col>
                                      <Col>
                                        <Popconfirm
                                          title="Delete the task"
                                          description="Are you sure to delete this task?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={(e) => {
                                            handleDeleteTask(item);
                                          }}
                                        >
                                          <DeleteOutlined
                                            className="text-secondary-red"
                                            style={{ fontSize: '16px' }}
                                          />
                                        </Popconfirm>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          ) : (
            <Empty
              description={
                <Text small12 className="text-grey">
                  There are currently no tasks in your list.
                </Text>
              }
            />
          )}
        </Col>
        <Divider style={{ margin: 0 }} />
        {!isViewMode && (
          <Col span={24}>
            <Row gutter={[8, 8]} justify="end">
              <Col>
                <Button
                  className="button-red"
                  icon={<PlusOutlined />}
                  onClick={(e) => {
                    setOpenViewModal(false);
                    handleOpenCreateOtModal();
                  }}
                >
                  Create New OT
                </Button>
              </Col>
              {!checkHoliday(selectedDate) &&
                !selectedDateData?.some(
                  (item) =>
                    item.type === 'task' &&
                    item.startTime === null &&
                    item.endTime === null,
                ) && (
                  <Col>
                    <Button
                      className="button-outlined"
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        setOpenViewModal(false);
                        handleOpenCreateTaskModal();
                      }}
                    >
                      Create New Task
                    </Button>
                  </Col>
                )}
            </Row>
          </Col>
        )}
      </Row>
    </Modal>
  );
};
export default TaskModal;
