import { Button, Col, Divider, Form, Modal, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import moment from 'moment';
import { HolidayService, LeaveService } from '../../services/api';
import { DatePicker } from '../DatePicker';
import FormInput from '../FormInput';
import { Select } from '../Select';
import confirm from 'antd/lib/modal/confirm';
import { Text } from '../Text';
import { Checkbox } from '../Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { formatDaysAndHours } from '../../services/formatDate';

import { ReactComponent as AnnualLeaveIcon } from '../../assets/icons/annualLeave.svg';
import { ReactComponent as PersonalLeaveIcon } from '../../assets/icons/personalLeave.svg';
import { ReactComponent as SickLeaveIcon } from '../../assets/icons/sickLeave.svg';

const SelectTimeSlots = styled(Select)`
  // max-width:14rem;
  width: 10rem;
  height: 2rem;
  // margin-left:2rem;
`;

const AddLeaveRequestModal = (props) => {
  const { addLeaveModalVisible, closeAddLeaveModal, onFetch, leaveTime } =
    props;
  const [form] = useForm();

  const [selectedStartLeaveDate, setSelectedStartLeaveDate] = useState(
    moment(),
  );
  const [isFullDay, setIsFullDay] = useState(false);
  const [isDifferentDay, setIsDifferentDay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prevFormStartTime, setPrevFormStartTime] = useState(
    moment({ hour: 9, minute: 0 }).format('HH:mm:ss'),
  );
  const [prevFormEndTime, setPrevFormEndTime] = useState(
    moment({ hour: 18, minute: 0 }).format('HH:mm:ss'),
  );
  const [remarkText, setRemarkText] = useState('');

  useEffect(() => {
    if (isFullDay) {
      form.setFieldsValue({
        startTime: moment({ hour: 9, minute: 0 }).format('HH:mm:ss'),
        endTime: moment({ hour: 18, minute: 0 }).format('HH:mm:ss'),
      });
    } else {
      form.setFieldsValue({
        startTime: prevFormStartTime,
        endTime: prevFormEndTime,
      });
    }
  }, [isFullDay]);

  const fetchHolidayByYear = async () => {
    return HolidayService.getYear(
      selectedStartLeaveDate.year(),
      ({ data }) => {
        return data;
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
          return null;
        }
      },
    );
  };

  const getTimeOption = () => {
    let res = [];
    let start = moment({ hour: 9, minute: 0 });
    while (start.hour() != 18 + 1) {
      res.push(
        <Select.Option value={start.format('HH:mm:ss')}>
          {start.format('HH:mm')}
        </Select.Option>,
      );
      start.add(1, 'hours');
    }

    return res;
  };

  const submitLeaveForm = async (values) => {
    const holidayByYear = await fetchHolidayByYear();
    const timestampFormatDB = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    const startLeaveDate = values.dateRange[0];
    const endLeaveDate = values.dateRange[1];

    for (var i = 0; i < holidayByYear.length; i++) {
      const holidayMoment = moment(
        holidayByYear[i]['holidayDate'],
        timestampFormatDB,
      );
      if (
        startLeaveDate.isSame(holidayMoment, 'day') ||
        endLeaveDate.isSame(holidayMoment, 'day')
      ) {
        message.error(
          `Can\'t create leave on holiday ${moment(
            holidayByYear[i]['holidayDate'],
          ).format('ddd MMM DD YYYY')}`,
        );
        return;
      }
    }

    const payload = {
      startDate: values.dateRange[0].format('YYYY-MM-DD'),
      endDate: values.dateRange[1].format('YYYY-MM-DD'),
      isSpecificTime: !(isFullDay || isDifferentDay),
      startTime:
        values.dateRange[0].format('YYYY-MM-DD') +
        ' ' +
        (isFullDay || isDifferentDay
          ? moment({ hour: 9, minute: 0 }).format('HH:mm:ss')
          : values.startTime),
      endTime:
        values.dateRange[1].format('YYYY-MM-DD') +
        ' ' +
        (isFullDay || isDifferentDay
          ? moment({ hour: 18, minute: 0 }).format('HH:mm:ss')
          : values.endTime),
      purpose: values.description,
      leaveTypeId: parseInt(values.leaveType),
    };
    if (moment(values.dateRange[0]).isBefore(moment(), 'day')) {
      confirm({
        title: 'Warning!',
        content:
          'Leave start date is in the past. Can only be canceled by Admin. Are you sure you want to continue?',
        onOk: () => {
          handleAddLeaveForm(payload);
        },
        onCancel: () => {},
      });
    } else {
      handleAddLeaveForm(payload);
    }
  };

  const handleAddLeaveForm = (payload) => {
    setIsLoading(true);
    const messageKey = 'creating-leave';
    LeaveService.addLeave(
      payload,
      ({ data }) => {
        setIsLoading(false);
        form.resetFields();
        message.success({
          key: messageKey,
          content: 'Leave request has been created successfully.',
        });
        setTimeout(() => {
          onFetch();
        }, 1000);
        closeAddLeaveModal();
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error({
            key: messageKey,
            content: 'Failed to create leave request: ' + response.data.message,
          });
        }
      },
    );
  };

  return (
    <Modal
      width="500px"
      bodyStyle={{
        maxHeight: '720px',
        overflowY: 'auto',
      }}
      open={addLeaveModalVisible}
      footer={
        <Row gutter={[16, 16]} style={{ justifyContent: 'space-between' }}>
          <Col>
            <Button
              className="button-outlined"
              onClick={() => {
                form.resetFields();
                closeAddLeaveModal();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={isLoading}
              className="button-primary"
              type="primary"
              onClick={() => form.submit()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      }
      centered
      onCancel={() => {
        form.resetFields();
        closeAddLeaveModal();
      }}
      maskClosable
    >
      <Form
        form={form}
        onFinish={submitLeaveForm}
        onValuesChange={(changedValues, allValues) => {
          if (
            changedValues.dateRange &&
            (changedValues.dateRange[0] != undefined ||
              changedValues.dateRange[1] != undefined)
          ) {
            allValues.dateRange[0].format('YYYY-MM-DD') !=
            allValues.dateRange[1].format('YYYY-MM-DD')
              ? setIsDifferentDay(true)
              : setIsDifferentDay(false);
          }
        }}
        requiredMark={true}
        initialValues={{
          leaveType: '1',
          dateRange: [moment(), moment()],
        }}
      >
        <Row>
          <Text h4 className="text-normal">
            Create Request
          </Text>
        </Row>
        <Divider style={{ margin: '8px 0' }} />
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FormInput
              title="Leave Type"
              required
              component={
                <Form.Item
                  style={{ margin: '0' }}
                  name="leaveType"
                  rules={[
                    {
                      required: true,
                      message: 'Please select leave type.',
                    },
                  ]}
                >
                  <Select
                    id="leaveType"
                    defaultValue={'Annual Leave'}
                    onChange={(values) =>
                      form.setFieldsValue({
                        leaveType: values,
                      })
                    }
                    style={{ width: '100%' }}
                  >
                    <Select.Option value="1">Annual Leave</Select.Option>
                    <Select.Option value="2">Personal Leave</Select.Option>
                    <Select.Option value="3">Sick Leave</Select.Option>
                  </Select>
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Pick Date"
              required
              component={
                <Form.Item
                  style={{ margin: '0' }}
                  name="dateRange"
                  rules={[
                    {
                      required: true,
                      message: 'Please input leave date.',
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    disabledDate={(current) => current > moment().endOf('year')}
                    format="DD/MM/YYYY"
                    onChange={(dates) => {
                      setSelectedStartLeaveDate(dates[0]);
                    }}
                  />
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Pick Time"
              required
              component={
                <Row direction="row" alignItems="center" gutter={[8, 8]}>
                  <Col>
                    <Form.Item
                      style={{ margin: '0' }}
                      name="startTime"
                      rules={[
                        {
                          required: !(isFullDay || isDifferentDay),
                          message: 'Please input leave time.',
                        },
                      ]}
                      initialValue={moment({ hour: 9, minute: 0 }).format(
                        'HH:mm:ss',
                      )}
                    >
                      {!isFullDay && !isDifferentDay ? (
                        <SelectTimeSlots
                          onChange={(value) => setPrevFormStartTime(value)}
                          style={{ width: '160px' /* , resize: 'none' */ }}
                        >
                          {getTimeOption()}
                        </SelectTimeSlots>
                      ) : (
                        <SelectTimeSlots style={{ width: '160px' }} disabled>
                          <Select.Option
                            value={moment({ hour: 9, minute: 0 }).format(
                              'HH:mm:ss',
                            )}
                          >
                            {moment({ hour: 9, minute: 0 }).format('HH:mm')}
                          </Select.Option>
                        </SelectTimeSlots>
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Text small12 className="text-normal">
                      -
                    </Text>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{ margin: '0' }}
                      name="endTime"
                      rules={[
                        {
                          required: !(isFullDay || isDifferentDay),
                          message: 'Please input leave time.',
                        },
                        () => ({
                          validator: (_, value) => {
                            const startTimeMoment = moment(
                              form.getFieldValue('startTime'),
                              'HH:mm',
                            );
                            const endTimeMoment = moment(value, 'HH:mm');
                            if (endTimeMoment.isBefore(startTimeMoment)) {
                              return Promise.reject(
                                'End time must be greater than Start time.',
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      initialValue={moment({ hour: 18, minute: 0 }).format(
                        'HH:mm:ss',
                      )}
                    >
                      {!isFullDay && !isDifferentDay ? (
                        <SelectTimeSlots
                          onChange={(value) => setPrevFormEndTime(value)}
                          style={{ width: '160px' /* , resize: 'none' */ }}
                        >
                          {getTimeOption()}
                        </SelectTimeSlots>
                      ) : (
                        <SelectTimeSlots disabled style={{ width: '160px' }}>
                          <Select.Option
                            value={moment({ hour: 18, minute: 0 }).format(
                              'HH:mm:ss',
                            )}
                          >
                            {moment({ hour: 18, minute: 0 }).format('HH:mm')}
                          </Select.Option>
                        </SelectTimeSlots>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <Form.Item name="isFullDay" style={{ margin: 0 }}>
              <Checkbox
                className="checkbox-primary"
                onChange={(value) => {
                  form.setFieldsValue({
                    isFullDay: value.target.checked,
                  });
                  setIsFullDay(value.target.checked);
                }}
                checked={isFullDay || isDifferentDay}
                disabled={isDifferentDay}
                style={{ userSelect: 'none' }}
              >
                <Text small12>All day</Text>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormInput
              title="Purpose"
              required
              component={
                <Form.Item name="description" style={{ margin: 0 }}>
                  <TextArea
                    value={remarkText}
                    onChange={(e) => {
                      setRemarkText(e.target.value);
                    }}
                    placeholder="Purpose"
                    autoSize={{ minRows: 3, maxRows: 4 }}
                  />
                </Form.Item>
              }
            />
          </Col>
          {leaveTime && (
            <Col span={24}>
              <Row gutter={[8, 8]} style={{ justifyContent: 'space-between' }}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <AnnualLeaveIcon fill="#F4364C" style={{ marginRight: 5 }} />
                  <Text small12 className="text-normal">
                    {moment().year()} Annual Leave
                  </Text>
                </Col>
                <Col>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Text small12 className="text-normal">
                        {leaveTime && (
                          <>
                            Used{' '}
                            <Text className="text-normal">
                              {formatDaysAndHours(
                                leaveTime?.annual.cummulativeHourUse,
                              )}
                            </Text>
                          </>
                        )}
                      </Text>
                    </Col>
                    <Col>
                      <Text sub4 className="text-normal">
                        {leaveTime && (
                          <>
                            Remains{' '}
                            <Text className="text-secondary-red">
                              {formatDaysAndHours(leaveTime?.annual.hourRemain)}
                            </Text>
                          </>
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          {leaveTime && (
            <Col span={24}>
              <Row gutter={[8, 8]} style={{ justifyContent: 'space-between' }}>
                <Col style={{ display: 'flex', alignItems: 'center' }} l>
                  <PersonalLeaveIcon
                    fill="#F4364C"
                    style={{ marginRight: 5 }}
                  />
                  <Text small12 className="text-normal">
                    {moment().year()} Personal Leave
                  </Text>
                </Col>
                <Col>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Text small12 className="text-normal">
                        {leaveTime && (
                          <>
                            Used{' '}
                            <Text className="text-normal">
                              {formatDaysAndHours(
                                leaveTime?.personal.cummulativeHourUse,
                              )}
                            </Text>
                          </>
                        )}
                      </Text>
                    </Col>
                    <Col>
                      <Text sub4 className="text-normal">
                        {leaveTime && (
                          <>
                            Remains{' '}
                            <Text className="text-secondary-red">
                              {formatDaysAndHours(
                                leaveTime?.personal.hourRemain,
                              )}
                            </Text>
                          </>
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          {leaveTime && (
            <Col span={24}>
              <Row gutter={[8, 8]} style={{ justifyContent: 'space-between' }}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <SickLeaveIcon fill="#F4364C" style={{ marginRight: 5 }} />
                  <Text small12 className="text-normal">
                    {moment().year()} Sick Leave
                  </Text>
                </Col>
                <Col>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Text small12 className="text-normal">
                        {leaveTime && (
                          <>
                            Used{' '}
                            <Text className="text-normal">
                              {formatDaysAndHours(
                                leaveTime?.sick.cummulativeHourUse,
                              )}
                            </Text>
                          </>
                        )}
                      </Text>
                    </Col>
                    <Col>
                      <Text sub4 className="text-normal">
                        {leaveTime && (
                          <>
                            Remains{' '}
                            <Text className="text-secondary-red">
                              {formatDaysAndHours(leaveTime?.sick.hourRemain)}
                            </Text>
                          </>
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};
export default AddLeaveRequestModal;
