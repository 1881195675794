export const parseRequestFormData = (requestObject, formDataFileList = []) => {
  const formData = new FormData();

  Object.keys(requestObject).forEach((key) =>
    formData.append(key, requestObject[key]),
  );

  formDataFileList.forEach((formFile) => {
    if (formFile.file) {
      formData.append(formFile.fieldName, formFile.file);
    }
  });

  return formData;
};
